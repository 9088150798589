exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1yPny{margin-left:5px}._2zIh3{color:#28a745}._3-VIz{margin-left:5px}", ""]);

// exports
exports.locals = {
	"root": "_1yPny",
	"icon": "_2zIh3",
	"message": "_3-VIz"
};