exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2yKr3{font-size:11.2px;font-size:.7rem;background:none}._3J5EX{padding-top:8px;padding-top:.5rem;padding-bottom:8px;padding-bottom:.5rem;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}._2Ka2m{font-weight:600;display:block}", ""]);

// exports
exports.locals = {
	"tech_breadcrumbs": "_2yKr3",
	"tech_item": "_3J5EX",
	"info_label": "_2Ka2m"
};