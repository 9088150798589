import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

import s from './Panel.css';

const Panel = ({ className, ...other }) => (
  <div className={cx(s.root, className)} {...other} />
);

Panel.propTypes = {
  className: PropTypes.string,
};

Panel.defaultProps = {
  className: '',
};

export default withStyles(s)(Panel);
