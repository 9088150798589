import React from 'react';
import { Badge } from 'reactstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Icon from 'react-icons-kit';
import { starO } from 'react-icons-kit/fa/starO';

import s from './TopProposalLabel.css';

const TopProposalLabel = () => (
  <div className={s.root}>
    <Badge className={s.label} color="warning">
      <Icon icon={starO} />
      <span className={s.text}>пропозиція ТОП</span>
    </Badge>
  </div>
);

export default withStyles(s)(TopProposalLabel);
