exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1pHN8{text-align:right;float:right;margin-top:5px}.YF-gC{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;border-radius:0}.VHClh{margin-left:3px}", ""]);

// exports
exports.locals = {
	"root": "_1pHN8",
	"label": "YF-gC",
	"text": "VHClh"
};