import React from 'react';
import PropTypes from 'prop-types';
import { Adsense } from '@ctrl/react-adsense';

const DEFAULT_CLIENT = 'ca-pub-7789914325435608';

const AdSenseBanner = ({
  client,
  slot,
  style,
  layout,
  layoutKey,
  format,
  adsense,
  ...props
}) => {
  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <div
      {...{
        ...(adsense.id ? { className: adsense.id } : null),
      }}
    >
      <Adsense
        client={client}
        slot={slot}
        style={style}
        layout={layout}
        layoutKey={layoutKey}
        format={format}
        {...{
          ...(adsense.id ? { className: adsense.id } : null),
        }}
        {...props}
      />
    </div>
  );
};

AdSenseBanner.propTypes = {
  client: PropTypes.string,
  slot: PropTypes.string.isRequired,
  layout: PropTypes.string,
  layoutKey: PropTypes.string,
  style: PropTypes.shape(),
  format: PropTypes.string,
  adsense: PropTypes.shape(),
};

AdSenseBanner.defaultProps = {
  client: DEFAULT_CLIENT,
  style: undefined,
  layout: undefined,
  layoutKey: undefined,
  format: 'auto',
  adsense: {},
};

export default AdSenseBanner;
