exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Qqbv{padding-bottom:20px}._3MkKv{display:block;height:100%;text-decoration:none!important}@media (max-width:575.98px){._3MkKv{min-height:200px}}._1V5xZ{font-size:.8em}._1AGao{font-size:.7em;color:#ccc}", ""]);

// exports
exports.locals = {
	"small_item": "_2Qqbv",
	"image": "_3MkKv",
	"intro": "_1V5xZ",
	"date": "_1AGao"
};