import React from 'react';
import PropTypes from 'prop-types';

import Breadcrumbs from './Breadcrumbs';
import VehicleOffer from './VehicleOffer';
import Product from './Product';
import WebSite from './WebSite';
import Organization from './Organization';

const Schema = ({ schema: { breadcrumbs, vehicleOffer, product } }) => (
  <div>
    <Breadcrumbs breadcrumbs={breadcrumbs} />
    <VehicleOffer vehicleOffer={vehicleOffer} />
    <Product product={product || vehicleOffer} />
    <WebSite />
    <Organization />
  </div>
);

Schema.propTypes = {
  schema: PropTypes.shape(),
};

Schema.defaultProps = {
  schema: {},
};

export default Schema;
