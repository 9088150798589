import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Select from 'react-select';
import selectStyles from 'react-select/dist/react-select.css';
import each from 'lodash/each';

import RegionOption from './RegionOption';

const cleanValue = (option, method) => {
  let value = '';
  if (option && option.value) {
    value = option.value;
  }
  method(value);
};

const renderOption = (option, region = false) => ({
  label: option.title,
  value: option.id,
  region,
});

// const valueComponent = (props) => {
//
// 		return (
// 			<div className={`Select-value`} title={props.value.title}>
// 				<span className="Select-value-label">
// 					{props.children}
// 				</span>
// 			</div>
// 		);
// }

class LocationsSelect extends React.Component {
  static propTypes = {
    input: PropTypes.shape().isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape()),
    emptyLabel: PropTypes.string,
    meta: PropTypes.shape({
      touched: PropTypes.bool.isRequired,
      error: PropTypes.string,
    }).isRequired,
    required: PropTypes.bool,
  };

  static defaultProps = {
    options: [],
    emptyLabel: '',
    label: '',
    required: false,
  };

  render() {
    const {
      input,
      label,
      options,
      emptyLabel,
      meta: { touched, error },
      required,
    } = this.props;
    const hasError = touched && error;
    const empty = emptyLabel || label;
    // prepare options
    const fineOptions = [];
    each(options, option => {
      fineOptions.push(renderOption(option, true));
      if (option.childrens) {
        each(option.childrens, o => fineOptions.push(renderOption(o)));
      }
    });

    return (
      <div className="form-group">
        {label && <span>{label}</span>}
        {required && label && <span className="text-danger">*</span>}
        <div>
          <Select
            value={input.value}
            onChange={o => cleanValue(o, input.onChange)}
            onBlur={() => input.onBlur(input.value)}
            onFocus={input.onFocus}
            options={fineOptions}
            className={`${hasError ? ' is-invalid' : ''}`}
            placeholder={empty}
            onBlurResetsInput={false}
            optionComponent={RegionOption}
            // valueComponent={valueComponent}
          />
          {hasError && <small className="text-danger">{error}</small>}
        </div>
      </div>
    );
  }
}

export default withStyles(selectStyles)(LocationsSelect);
