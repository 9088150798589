exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3fhUw{min-width:25px;margin:2px 5px 2px 0}@media (max-width:576px){._3fhUw{min-width:35px;margin:2px 15px 2px 0}}", ""]);

// exports
exports.locals = {
	"icon": "_3fhUw"
};