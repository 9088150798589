import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'reactstrap';
import Icon from 'react-icons-kit';
import { camera } from 'react-icons-kit/fa/camera';
import { refresh } from 'react-icons-kit/fa/refresh';
import { mapMarker } from 'react-icons-kit/fa/mapMarker';
import { infoCircle } from 'react-icons-kit/fa/infoCircle';
import concat from 'lodash/concat';
import slice from 'lodash/slice';

import * as GA from '../../ga';
import { withLocalize } from '../../locales';
import ActionMenu from '../../containers/ActionMenu';
import Link from '../Link';
import ThumborImage from '../ThumborImage';
import Price from '../Helpers/Price';
import Statuses from '../Helpers/Statuses';
import SmallGallery from '../Helpers/SmallGallery';
import WithDiscountLabel from '../Helpers/WithDiscountLabel';
import SuperProposalLabel from '../Helpers/SuperProposalLabel';
import TopProposalLabel from '../Helpers/TopProposalLabel';
import { parseServices } from '../Services/helpers';

import s from './CarItem.css';
import TechInfo from './TechInfo';
import messages from './messages';
import VerifiedLabel from '../VerifiedLabel';

class CarItem extends Component {
  static propTypes = {
    car: PropTypes.shape().isRequired,
    services: PropTypes.arrayOf(PropTypes.shape()),
    localize: PropTypes.func.isRequired,
  };

  static contextTypes = { fetch: PropTypes.func.isRequired };

  static defaultProps = {
    services: [],
  };

  getImage = () => {
    const images = this.images();
    return images.length > 0 ? images[0].url : this.noPhotoUrl;
  };

  static noPhotoUrl = 'no_photo.jpg';

  images = () => {
    const { images, modification } = this.props.car;
    return concat(
      images && images.length > 0 ? images : [],
      modification &&
        modification.generation &&
        modification.generation.images &&
        modification.generation.images.length > 0
        ? modification.generation.images
        : [],
    );
  };

  render() {
    if (!this.props.car) {
      return null;
    }

    const { car, services, localize } = this.props;
    const {
      offerOfDay,
      labelWithDiscount,
      labelBestPrice,
      labelSuperPrice,
      extendedFormat,
      listMorePhoto,
      labelSuperProposal,
      labelTopProposal,
    } = parseServices(services);

    GA.offerShownEvent(car.id);

    const featured = offerOfDay || labelBestPrice || labelSuperPrice;

    const imagesCount = this.images().length;

    return (
      <div className={`${s.root} ${featured ? s.featured : ''}`}>
        <ActionMenu car={car} fetch={this.context.fetch} />
        <Row>
          <Col md="4" xs="12" className={s.image}>
            {imagesCount > 0 && (
              <div className={`badge badge-dark ${s.media_info}`}>
                <Icon icon={camera} size={12} /> <span>{imagesCount}</span>
              </div>
            )}
            <Link to={`/car/${car.id}`}>
              <ThumborImage
                width={350}
                src={this.getImage()}
                alt={`${localize('Продаж')} - ${car.title}`}
                background
                className={s.img}
              />
            </Link>
          </Col>
          <Col md="8" xs="12" className={s.description}>
            <Row>
              <Col md="9" xs="7">
                <Link to={`/car/${car.id}`} className={s.title}>
                  {car.title}
                  {car.is_verified_by_payment && <VerifiedLabel id={car.id} />}
                </Link>
                {car.modification && (
                  <p className={s.modification}>{car.modification.title}</p>
                )}
              </Col>
              <Col md="3" xs="5">
                <Price
                  price={car.price}
                  currency={car.price_currency}
                  priceUah={car.price_uah}
                  priceUsd={car.price_usd}
                  best={labelBestPrice}
                  isSuper={labelSuperPrice}
                />
                {labelWithDiscount && <WithDiscountLabel />}
                {labelSuperProposal && <SuperProposalLabel />}
                {labelTopProposal && <TopProposalLabel />}
              </Col>
            </Row>
            <Row>
              <Col md="12" className={s.info}>
                <TechInfo car={car} />
                <Statuses car={car} />
              </Col>
            </Row>
            <Row>
              <Col xs="6" className={s.location}>
                <Icon icon={mapMarker} size={12} /> {car.location.title}
              </Col>
              <Col xs="6">
                <div className={s.refreshed}>
                  <Icon icon={refresh} size={8} /> {moment().to(car.updated)}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {extendedFormat && car.description ? (
          <Row className={s.description_row}>
            <Col md="4" className={`${s.additional_label} d-none d-sm-block`}>
              <span className="d-none d-sm-block">
                {localize('Додаткова інформація')}{' '}
                <Icon icon={infoCircle} size={18} />
              </span>
            </Col>
            <Col md="8" xs="12">
              <div className={s.description}>
                {slice(car.description, 0, 130)}...
              </div>
            </Col>
          </Row>
        ) : null}
        {listMorePhoto && (
          <SmallGallery
            images={this.images()}
            title={`${localize('Продаж')} - ${car.title}`}
          />
        )}
      </div>
    );
  }
}

export default withLocalize(messages)(withStyles(s)(CarItem));
