exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Roboto\",sans-serif;--link-color:#333;--link-color-ligther:rgba(51,51,51,.7);--link-hover-color:rgba(27,199,68,.9);--ap-orange-color:#ffaa4d;--text-white-stroke-shadow:-1px -1px 0 #fff,1px -1px 0 #fff,-1px 1px 0 #fff,1px 1px 0 #fff;--text-black-stroke-shadow:-1px -1px 0 #333,1px -1px 0 #333,-1px 1px 0 #333,1px 1px 0 #333;--max-content-width:1400px;--backgroud-body:#f9f9f9;--border-main-style:1px solid #eee;--main-box-shadow:0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.08);--item-box-shadow:0 0 4px 1px rgba(0,0,0,.08);--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--list-item-label-color:#1bc744;--price-background-color:#1bc744;--service-box-shadow:0 0 3.2px 3.2px rgba(51,51,51,.2);--service-box-shadow:0 0 0.2rem 0.2rem rgba(51,51,51,.2)}._1eQK6{font-size:16px;font-size:1rem;text-align:center}._2Unbw{text-decoration:none!important;-webkit-box-shadow:0 0 4px 1px rgba(0,0,0,.08);-webkit-box-shadow:var(--item-box-shadow);box-shadow:0 0 4px 1px rgba(0,0,0,.08);box-shadow:var(--item-box-shadow)}._1I6Cj,.Z2kZx{min-height:220px}._3rikx{position:absolute;color:#fff;padding:5px 15px;top:0;font-size:19.2px;font-size:1.2rem}._2_p-4,._3rikx{text-shadow:-1px -1px 0 #333,1px -1px 0 #333,-1px 1px 0 #333,1px 1px 0 #333;text-shadow:var(--text-black-stroke-shadow)}._2_p-4{color:#ffaa4d!important;position:absolute!important;top:24px!important;top:1.5rem!important;padding:5px!important;background:none!important;margin-left:0!important;text-align:left!important}._2_p-4 i{font-size:27.2px;font-size:1.7rem}._2_p-4 div{text-shadow:-1px -1px 0 #333,1px -1px 0 #333,-1px 1px 0 #333,1px 1px 0 #333;text-shadow:var(--text-black-stroke-shadow)}._2_p-4:before{background:none!important;z-index:-2}", ""]);

// exports
exports.locals = {
	"root": "_1eQK6",
	"image": "_2Unbw",
	"image_inner": "_1I6Cj",
	"noPhoto": "Z2kZx",
	"title": "_3rikx",
	"price_item": "_2_p-4"
};