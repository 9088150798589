import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Panel from 'components/Helpers/Panel';
import Links from 'components/Catalog/Links';
import MakeIcon from 'components/Helpers/MakeIcon';
import { getMakeUrl } from 'routes/seo/urls';

import s from './Makes.css';

const Makes = ({ makes }) => (
  <Panel>
    <p className="h4">
      <FormattedMessage
        id="home.popular_makes.heading"
        defaultMessage="Популярні марки"
        description=""
      />
    </p>
    <hr className="my-2" />
    <Links
      catalogItems={makes}
      link={item => getMakeUrl(item)}
      icon={item => (
        <div className={s.icon}>
          <MakeIcon slug={item.slug} />
        </div>
      )}
      columns={4}
    />
  </Panel>
);

Makes.propTypes = {
  makes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default withStyles(s)(Makes);
