import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cn from 'classnames';

import Header from 'components/Header';
// import Feedback from '../Feedback';
import Footer from 'components/Footer';

import LoadingOverlay from 'containers/LoadingOverlay';
import Breadcrumbs from 'containers/Breadcrumbs';
import Notifications from 'containers/Notifications';
import AdSenseBanner from 'containers/AdSenseBanner';
import Schema from 'containers/Schema';

import normalizeCss from 'normalize.css';
import s from './Layout.css';
import backgroudSell from './sell_bg.png';

const STYLES_MAP = {
  sell: {
    backgroundImage: `url(${backgroudSell})`,
  },
};

const Layout = ({
  backgroundStyle,
  breadcrumbs,
  className,
  fluid,
  children,
}) => {
  const customStyle = STYLES_MAP[backgroundStyle];
  return (
    <div
      className={s.body}
      {...{
        ...(customStyle ? { style: customStyle } : null),
      }}
    >
      <Header />
      <div className={cn(`container${fluid ? '-fluid' : ''}`, className)}>
        {breadcrumbs && <Breadcrumbs />}
        {children}
        {/* <Feedback /> */}
      </div>
      <div className="container mb-4">
        <AdSenseBanner
          slot="5653381110"
          format="autorelaxed"
          style={{ display: 'block' }}
        />
      </div>
      <Footer />
      <LoadingOverlay />
      <Notifications />
      <Schema />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundStyle: PropTypes.string,
  breadcrumbs: PropTypes.bool,
  className: PropTypes.string,
  fluid: PropTypes.bool,
};

Layout.defaultProps = {
  backgroundStyle: '',
  breadcrumbs: true,
  className: null,
  fluid: false,
};

export default withStyles(normalizeCss, s)(Layout);
