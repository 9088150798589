import React from 'react';
import PropTypes from 'prop-types';
import IcoMoon from 'react-icomoon';
import iconSet from './selection-min.json';

const MakeIcon = ({ slug, ...props }) => (
  <IcoMoon iconSet={iconSet} icon={slug} {...props} />
);

MakeIcon.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default MakeIcon;
