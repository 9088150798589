import { defineMessages } from 'react-intl';

export default defineMessages({
  '{region}. Інші марки авто.': {
    id: 'seo:regionPage:otherMakes',
    defaultMessage: '{region}. Інші марки авто.',
  },
  'Інші регіони': {
    id: 'seo:regionPage:otherRegions',
    defaultMessage: 'Інші регіони',
  },
});
