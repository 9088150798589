import { connect } from 'react-redux';

import AdSenseBanner from 'components/AdSenseBanner';
// import { adsenseReload } from 'actions/adActions';

function mapStateToProps({ adsense }) {
  return { adsense };
}

export default connect(mapStateToProps, {})(AdSenseBanner);
