import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col, Card } from 'reactstrap';
import concat from 'lodash/concat';

import ThumborImage from '../../../ThumborImage';
import Link from '../../../Link';
import Price from '../../../Helpers/Price/Price';
import * as GA from '../../../../ga';
import s from './CarItem.css';

const getImages = car => {
  const { images, modification } = car;
  return concat(
    images && images.length > 0 ? images : [],
    modification &&
      modification.generation &&
      modification.generation.images &&
      modification.generation.images.length > 0
      ? modification.generation.images
      : [],
  );
};

const CarItem = props => {
  const { car } = props;
  const images = getImages(car);
  const image = images.length > 0 ? images[0].url : '';
  GA.offerShownEvent(car.id);

  return (
    <Row className={s.root}>
      <Col>
        <Card>
          <Link to={`/car/${car.id}`} className={s.image}>
            <ThumborImage
              width={350}
              className={s.image_inner}
              noPhotoClassName={s.noPhoto}
              src={image}
              alt={`Продаж - ${car.title}`}
              background
            />
            <p className={s.title}>{car.title}</p>
            <Price
              className={s.price_item}
              price={car.price}
              currency={car.price_currency}
              priceUah={car.price_uah}
              priceUsd={car.price_usd}
            />
          </Link>
        </Card>
      </Col>
    </Row>
  );
};

CarItem.propTypes = {
  car: PropTypes.shape().isRequired,
};

export default withStyles(s)(CarItem);
