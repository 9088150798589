import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ThumborImage.css';
import thumbnail from './thumbor';

import NoPhoto from './NoPhoto';

const ThumborImage = props => {
  const { src, width, height, background } = props;
  if (src) {
    const thumbnailUrl = thumbnail(src, width, height);
    if (background) {
      const styles = {
        backgroundImage: `url(${thumbnailUrl})`,
        backgroundSize: props.cover ? 'cover' : 'contain',
      };
      return (
        <div
          className={`${s.container_image} ${props.className}`}
          style={styles}
        >
          {props.alt}
        </div>
      );
    }
    return (
      <img className={props.className} src={thumbnailUrl} alt={props.alt} />
    );
  }
  return <NoPhoto className={props.noPhotoClassName} />;
};

ThumborImage.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  noPhotoClassName: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
  alt: PropTypes.string,
  background: PropTypes.bool,
  cover: PropTypes.bool,
};

ThumborImage.defaultProps = {
  className: '',
  noPhotoClassName: '',
  height: 0,
  alt: '',
  src: '',
  background: false,
  cover: true,
};

export default withStyles(s)(ThumborImage);
