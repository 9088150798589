exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WrG4z{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._3W5PV{margin-right:10px}", ""]);

// exports
exports.locals = {
	"title": "WrG4z",
	"icon": "_3W5PV"
};