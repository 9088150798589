import { defineMessages } from 'react-intl';

export default defineMessages({
  'Avtoprod.ua - Купити та продати авто. Найбільший авторинок України.': {
    id: 'home:seo_title',
    defaultMessage:
      'Avtoprod.ua - Купити та продати авто. Найбільший авторинок України.',
  },
  'Avtoprod.ua - вигідний продаж та легка купівля Б/В та нового авто в Україні. Тільки на avtoprod.ua автобазар реальних продавців авто.': {
    id: 'home:seo_description',
    defaultMessage:
      'Avtoprod.ua - вигідний продаж та легка купівля Б/В та нового авто в Україні. Тільки на avtoprod.ua автобазар реальних продавців авто.',
  },
});
