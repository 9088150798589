import React from 'react';
import Statistics from 'containers/Statistics';

export const useStatisticComponent = () => {
  const [visible, setVisible] = React.useState(false);
  return [
    props => <Statistics {...props} show={visible} setVisible={setVisible} />,
    setVisible,
  ];
};

export default undefined;
