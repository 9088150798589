exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3jcwo{font-size:1em;font-weight:300}._3jcwo a{text-decoration:underline}._2FvDx{display:none}._1-hVB{display:block}._2UKB6{color:#333;font-size:12px;padding:0;margin-bottom:20px}", ""]);

// exports
exports.locals = {
	"description_text": "_3jcwo",
	"more": "_2FvDx",
	"expanded": "_1-hVB",
	"btnMore": "_2UKB6"
};