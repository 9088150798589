exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._36v2j{font-size:12.8px;font-size:.8rem;display:-ms-flexbox;display:flex;margin-right:3px;margin-bottom:5px;margin-top:5px}", ""]);

// exports
exports.locals = {
	"info_badge": "_36v2j"
};