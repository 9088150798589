import { defineMessages } from 'react-intl';

export default defineMessages({
  Попередня: {
    id: 'pagination:prev',
    defaultMessage: 'Попередня',
  },
  Наступна: {
    id: 'pagination:next',
    defaultMessage: 'Наступна',
  },
  Початок: {
    id: 'pagination:start',
    defaultMessage: 'Початок',
  },
  Кінець: {
    id: 'pagination:end',
    defaultMessage: 'Кінець',
  },
});
