import { defineMessages } from 'react-intl';

export default defineMessages({
  Регіон: {
    id: 'search_form:region:label',
    defaultMessage: 'Регіон',
  },
  Марка: {
    id: 'search_form:make:label',
    defaultMessage: 'Марка',
  },
  Модель: {
    id: 'search_form:model:label',
    defaultMessage: 'Модель',
  },
  'спочатку оберіть марку': {
    id: 'search_form:model:emptyText',
    defaultMessage: 'спочатку оберіть марку',
  },
  'Рік від': {
    id: 'search_form:year_f:label',
    defaultMessage: 'Рік від',
  },
  до: {
    id: 'search_form:year_t:label',
    defaultMessage: 'до',
  },
  'Ціна $ від': {
    id: 'search_form:price_f:label',
    defaultMessage: 'Ціна $ від',
  },
  'Пробіг від': {
    id: 'search_form:mileage_f:label',
    defaultMessage: 'Пробіг від',
  },
  Кузов: {
    id: 'search_form:body:label',
    defaultMessage: 'Кузов',
  },
  Двигун: {
    id: 'search_form:engine:label',
    defaultMessage: 'Двигун',
  },
  КПП: {
    id: 'search_form:gears:label',
    defaultMessage: 'КПП',
  },
  менше: {
    id: 'search_form:less:label',
    defaultMessage: 'менше',
  },
  більше: {
    id: 'search_form:more:label',
    defaultMessage: 'більше',
  },
  фільтрів: {
    id: 'search_form:filters:label',
    defaultMessage: 'фільтрів',
  },
  Пошук: {
    id: 'search_form:search:label',
    defaultMessage: 'Пошук',
  },
});
