import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import history from '../../history';
import { makeLocaleUrl } from '../../locales';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

class Link extends React.Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    localeIgnore: PropTypes.bool,
    locale: PropTypes.string,
    dispatch: PropTypes.func,
  };

  static defaultProps = {
    onClick: null,
    localeIgnore: false,
    locale: 'uk-UA',
    dispatch: () => undefined,
  };

  handleClick = event => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.preventDefault();
    history.push(this.renderTo());
  };

  renderTo = () => {
    const { localeIgnore, locale, to } = this.props;
    if (!localeIgnore) {
      return makeLocaleUrl(to, locale);
    }
    return to;
  };

  render() {
    const {
      children,
      localeIgnore,
      locale,
      dispatch,
      to,
      ...props
    } = this.props;

    return (
      <a href={this.renderTo()} {...props} onClick={this.handleClick}>
        {children}
      </a>
    );
  }
}

const mapStateToProps = ({ intl }) => ({
  locale: intl.locale,
});

export default connect(mapStateToProps)(Link);
