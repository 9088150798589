import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'reactstrap';
import Icon from 'react-icons-kit';
import { thumbsOUp } from 'react-icons-kit/fa/thumbsOUp';
import { FormattedMessage } from 'react-intl';

import s from './CarList.css';

import CarItem from '../CarItem';

const PayedCarList = props => {
  const { cars } = props;
  return (
    <div className={s.root}>
      <Row>
        <Col xs="12">
          <div className={`lead ${s.title}`}>
            <Icon icon={thumbsOUp} />{' '}
            <FormattedMessage
              id="services.day_best_list"
              defaultMessage="Пропозиції дня"
              description=""
            />
          </div>
        </Col>
        {cars.map(car => (
          <Col xs="12" md="6" key={`coleconom_${car.id}`}>
            <CarItem car={car} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

PayedCarList.propTypes = {
  cars: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default withStyles(s)(PayedCarList);
