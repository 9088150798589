import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import Link from '../../Link';
import s from './SeoDescriptionText.css';

const SeoDescriptionText = () => {
  const [isMore, setMore] = React.useState(false);
  return (
    <Row>
      <Col>
        <p className={s.description_text}>
          <FormattedMessage
            id="home.description_text.0"
            defaultMessage="Вас вітає сайт avtoprod.ua. Якщо втомились шукати автомобіль своєї мрії - заходьте на Автопрод, на сайті тільки реальні оголошення про продаж автомобілів. Кожного дня на сайт додають автомобілі України, а також авто з Європи. Нові та б/в автомобілі, маленькі машини, преміум авто і дешеві авто — все це ви можете знайти на avtoprod.ua."
            description=""
          />
        </p>
        <p className={s.description_text}>
          <FormattedMessage
            id="home.description_text.1"
            defaultMessage="Сайт avtoprod.ua - це величезний авто маркетплейс України, який спеціалізується на продажу транспортних засобів. Завдяки дуже простому пошуку авто на автопрод ви швидко знайдете транспортний засіб, який хочете купити. Купити автомобіль недорого або продати авто на сайті - всі ці послуги на сайті безкоштовні."
            description=""
          />
        </p>
        <p className={s.description_text}>
          <FormattedMessage
            id="home.description_text.2"
            defaultMessage="Хочете купити б/в транспортний засіб, автобус чи вантажне авто? На сайті avtoprod.ua ви знайдете багато оголошень від продавців авто та автодилерів. Багато продавців готові дати гарантію на б/в авто, наш сайт співпрацює тільки з реальними продавцями машин."
            description=""
          />
        </p>
        <p className={s.description_text}>
          <FormattedMessage
            id="home.description_text.3"
            defaultMessage="На автопрод широкий вибір авто. Купити автомобіль та продати можна на avtoprod.ua легко та швидко."
            description=""
          />
        </p>
        <p className={cn(s.description_text, s.more, { [s.expanded]: isMore })}>
          <strong>
            <FormattedMessage
              id="home.description_text.4"
              defaultMessage="Хочете продати авто?"
              description=""
            />
          </strong>{' '}
          <FormattedMessage
            id="home.description_text.4_1"
            defaultMessage="На сайті"
            description=""
          />{' '}
          <a href="https://avtoprod.ua">https://avtoprod.ua</a>{' '}
          <FormattedMessage
            id="home.description_text.4_2"
            defaultMessage="щодня додається багато різноманітних оголошень з продажу автомобілів з усіх регіонів України. Ви можете розмістити будь-яку кількість автомобілів на продаж безкоштовно. Для того, щоб покупець побачив автомобіль у пошуку необхідно натиснути «Додати оголошення» або «Розмістити БЕЗКОШТОВНО». Вкажіть основні дані по автомобілю: марка, модель, рік випуску, ціна, технічні характеристики. Додайте опис автомобіля: як есплуатувався, переваги авто, тощо. Вкажіть контактні дані, щоб потенційний покупець машини міг вам подзвонити або написати"
            description=""
          />
          .{' '}
          <Link to="/prodati/avto">
            <FormattedMessage
              id="home.description_text.4_3"
              defaultMessage="Продати авто"
              description=""
            />
          </Link>
        </p>
        <p className={cn(s.description_text, s.more, { [s.expanded]: isMore })}>
          <strong>
            <FormattedMessage
              id="home.description_text.5"
              defaultMessage="Хочете купити автомобіль?"
              description=""
            />
          </strong>{' '}
          <FormattedMessage
            id="home.description_text.5_1"
            defaultMessage="На сайті"
            description=""
          />{' '}
          <a href="https://avtoprod.ua">https://avtoprod.ua</a>{' '}
          <FormattedMessage
            id="home.description_text.5_2"
            defaultMessage="українці продають свої машини щодня. Тут ви можете знайти будь-які марки та моделі машин по кращим цінам. Для того, щоб вибрати автомобіль натисніть"
            description=""
          />{' '}
          <Link to="/cars">
            «
            <FormattedMessage
              id="home.description_text.5_3"
              defaultMessage="Знайти"
              description=""
            />
            »
          </Link>{' '}
          <FormattedMessage
            id="home.description_text.5_4"
            defaultMessage="або"
            description=""
          />{' '}
          «
          <FormattedMessage
            id="home.description_text.5_5"
            defaultMessage="Пошук"
            description=""
          />
          ».{' '}
          <FormattedMessage
            id="home.description_text.5_6"
            defaultMessage="Список оголошень можна фільтрувати за диапазоном цін, років, пробігу, а також вказавши технічні характеристики автомобіля"
            description=""
          />
          .
        </p>
        <p className={cn(s.description_text, s.more, { [s.expanded]: isMore })}>
          <FormattedMessage
            id="home.description_text.5_7"
            defaultMessage="Автопрод допоможе розрахувати вартість будь-якої поїздки. Сервіс"
            description=""
          />{' '}
          <Link to="/fuel/">
            «
            <FormattedMessage
              id="home.description_text.5_8"
              defaultMessage="Пальне"
              description=""
            />
            »
          </Link>{' '}
          <FormattedMessage
            id="home.description_text.5_9"
            defaultMessage="допоможе знайти ціну на пальне у вашому регіоні та на вашій улюбленій АЗС, а калькулятор розрахує вартість поїздки на вашому автомобілі"
            description=""
          />
          .
        </p>
        <Button
          color="link"
          onClick={() => setMore(!isMore)}
          className={cn(s.btnMore)}
        >
          {!isMore ? (
            <FormattedMessage
              id="home.description_text.read_more"
              defaultMessage="Читати більше"
              description=""
            />
          ) : (
            <FormattedMessage
              id="home.description_text.read_more_hide"
              defaultMessage="Сховати"
              description=""
            />
          )}
        </Button>
      </Col>
    </Row>
  );
};

export default withStyles(s)(SeoDescriptionText);
