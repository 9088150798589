import { defineMessages } from 'react-intl';

export default defineMessages({
  Продаж: {
    id: 'car:list:alt:sell',
    defaultMessage: 'Продаж',
  },
  'Додаткова інформація': {
    id: 'car:item:additional_info',
    defaultMessage: 'Додаткова інформація',
  },
});
