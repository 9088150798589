exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".vNvnd{margin-bottom:32px;margin-bottom:2rem}._2-HSu{margin-bottom:16px;margin-bottom:1rem}._2-HSu a{font-size:.85em}", ""]);

// exports
exports.locals = {
	"root": "vNvnd",
	"links": "_2-HSu"
};