import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Icon from 'react-icons-kit';
import { user } from 'react-icons-kit/fa/user';
import { FormattedMessage } from 'react-intl';

import s from './AccountMenu.css';
import Link from '../Link';

class AccountMenu extends React.Component {
  static propTypes = {
    auth: PropTypes.shape({
      session: PropTypes.bool,
    }).isRequired,
    profile: PropTypes.shape().isRequired,
  };

  render() {
    const to = !this.props.auth.session
      ? '/login?next=/dashboard/cars'
      : '/logout';
    const { username } = this.props.profile;

    return (
      <div className={`${s.root} ml-auto mr-2`}>
        {username && (
          <span className={`mr-3 ${s.name}`}>
            <Icon icon={user} size={12} /> {username}
          </span>
        )}
        <Link className={s.link} to={to}>
          {!this.props.auth.session ? (
            <FormattedMessage
              id="top.account.enter"
              defaultMessage="Увійти"
              description=""
            />
          ) : (
            <FormattedMessage
              id="top.account.exit"
              defaultMessage="Вийти"
              description=""
            />
          )}
        </Link>
      </div>
    );
  }
}

export default withStyles(s)(AccountMenu);
