import range from 'lodash/range';
import moment from 'moment';

const START_YEAR = 1950;

export function years(start = START_YEAR, finish = moment().year()) {
  return range(finish, start);
}

export default undefined;
