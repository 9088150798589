import React from 'react';
import PropTypes from 'prop-types';
import URI from 'urijs';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import range from 'lodash/range';

import { withLocalize } from '../../locales';
import s from './Pagination.css';
import Link from '../Link';
import messages from './messages';

const PageItem = props => {
  const disabled = props.disabled || false;
  const page = props.children;
  const current = props.current || false;
  const { to, title, className } = props;
  const classNameString = `page-link ${className}`;
  return (
    <li
      className={`page-item${disabled ? ' disabled' : ''}${
        current ? ' active' : ''
      }`}
    >
      {disabled || current || !to ? (
        <span className={classNameString}>{page}</span>
      ) : (
        <Link to={to} className={classNameString} title={title}>
          {page}
        </Link>
      )}
    </li>
  );
};

PageItem.propTypes = {
  disabled: PropTypes.bool,
  current: PropTypes.bool,
  children: PropTypes.string.isRequired,
  to: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
};

PageItem.defaultProps = {
  disabled: false,
  current: false,
  title: undefined,
  to: undefined,
  className: '',
};

class Pagination extends React.Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    query: PropTypes.shape().isRequired,
    count: PropTypes.number,
    limit: PropTypes.number,
    localize: PropTypes.func.isRequired,
  };

  static defaultProps = {
    limit: 20,
    count: 0,
  };

  getTo = page => {
    const { query, path } = this.props;
    return new URI(path).search({ ...query, page }).toString();
  };

  getPagesToRender = (current, total) => {
    const start = current - this.sideLimit > 0 ? current - this.sideLimit : 1;

    let end = current + this.sideLimit;
    if (end > total) {
      end = total;
    }

    return range(start, end + 1);
  };

  sideLimit = 2;

  renderPrevious = current => {
    const { localize } = this.props;
    let disabled = true;
    if (current > 1) {
      disabled = false;
    }

    return (
      <PageItem
        to={this.getTo(current - 1)}
        title={localize('Попередня')}
        disabled={disabled}
      >
        &larr;
      </PageItem>
    );
  };

  renderNext = (current, total) => {
    const { localize } = this.props;
    let disabled = true;
    if (current < total) {
      disabled = false;
    }
    return (
      <PageItem
        to={this.getTo(current + 1)}
        disabled={disabled}
        title={localize('Наступна')}
      >
        &rarr;
      </PageItem>
    );
  };

  render() {
    if (!this.props.count) {
      return null;
    }
    const { count, limit, localize, query } = this.props;

    const total = Math.ceil(count / limit);
    const currentPage = parseInt(query.page || 1, 10);
    const pages = this.getPagesToRender(currentPage, total);
    const leftDots = currentPage - this.sideLimit > 1;
    const rightDots = currentPage + this.sideLimit < total;

    const renderedPages = pages.map(page => (
      <PageItem key={page} current={page === currentPage} to={this.getTo(page)}>
        {`${page}`}
      </PageItem>
    ));

    return (
      <div>
        <ul className={`pagination ${s.root}`}>
          <PageItem
            to={this.getTo(1)}
            disabled={currentPage === 1}
            className="d-none d-sm-block"
          >
            {localize('Початок')}
          </PageItem>
          {this.renderPrevious(currentPage)}
          {leftDots && <PageItem disabled>...</PageItem>}
          {renderedPages}
          {rightDots && <PageItem disabled>...</PageItem>}
          {this.renderNext(currentPage, total)}
          <PageItem
            to={this.getTo(total)}
            disabled={total === currentPage}
            className="d-none d-sm-block"
          >
            {localize('Кінець')}
          </PageItem>
        </ul>
      </div>
    );
  }
}

export default withStyles(s)(withLocalize(messages)(Pagination));
