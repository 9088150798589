import React from 'react';
import PropTypes from 'prop-types';
import JsonLd from './JsonLd';

const Breadcrumbs = ({ breadcrumbs }) => {
  const data = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': item.href || '',
        name: item.title,
      },
    })),
  };
  if (breadcrumbs.length > 0) {
    return <JsonLd data={data} />;
  }
  return null;
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape()),
};

Breadcrumbs.defaultProps = {
  breadcrumbs: [],
};

export default Breadcrumbs;
