import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseNotifications from 'react-notification-system-redux';

class Notifications extends Component {
  static contextTypes = { store: PropTypes.object };

  static propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  render() {
    const { notifications } = this.props;

    const style = {
      NotificationItem: {
        DefaultStyle: {
          margin: '10px 5px 2px 1px',
        },
        // success: {
        //   color: 'red'
        // }
      },
    };

    return <BaseNotifications notifications={notifications} style={style} />;
  }
}

export default Notifications;
