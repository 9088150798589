import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Icon } from 'react-icons-kit';
import { checkCircle } from 'react-icons-kit/fa/checkCircle';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import s from './VerifiedLabel.css';

const VerifiedLabel = ({ id, tooltip, text, className }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const labelId = `verifiedLabel_${id}`;

  const message = text || (
    <FormattedMessage
      id="verified.label.text"
      defaultMessage="користувач підтвердив продаж даними банківської картки"
      description=""
    />
  );

  return (
    <span className={cn(s.root, className)}>
      <Icon icon={checkCircle} className={s.icon} id={labelId} />
      {tooltip ? (
        <Tooltip
          // autohide
          flip="true"
          isOpen={tooltipOpen}
          target={labelId}
          toggle={toggle}
        >
          {message}
        </Tooltip>
      ) : (
        <span className={s.message}>{message}</span>
      )}
    </span>
  );
};

VerifiedLabel.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tooltip: PropTypes.bool,
  text: PropTypes.element,
  className: PropTypes.string,
};

VerifiedLabel.defaultProps = {
  tooltip: true,
  text: null,
  className: null,
};

export default withStyles(s)(VerifiedLabel);
