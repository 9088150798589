exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1PYQC{margin-bottom:16px;margin-bottom:1rem}._1ZEpH a{font-size:.9em}", ""]);

// exports
exports.locals = {
	"links": "_1PYQC",
	"allMakes": "_1ZEpH"
};