import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment';
import { itemUrl, listUrl } from 'routes/blog/urls';
import { FormattedMessage } from 'react-intl';

import ThumborImage from 'components/ThumborImage';
import Link from 'components/Link';
import Panel from 'components/Helpers/Panel';

import s from './BlogWidget.css';

const SmallItem = props => {
  const {
    item,
    item: { meta: { first_published_at: firstPublishedAt } },
    withIntro,
    vertical = true,
  } = props;
  const url = itemUrl(item);

  return (
    <Col xs={vertical ? 12 : 6}>
      <Row className={s.small_item}>
        <Col md="5" xs="12">
          <Link to={url} className={s.image}>
            <ThumborImage
              width={300}
              src={item.thumbnail.url}
              alt={item.title}
              background
            />
          </Link>
        </Col>
        <Col xs="12" md="7">
          <div>
            <Link to={url}>{item.title}</Link>
          </div>
          {(withIntro || !vertical) && (
            <div className={s.intro}>{item.intro}</div>
          )}
          <div className={s.date}>
            {moment(firstPublishedAt).format('DD.MM.YYYY')}
          </div>
        </Col>
      </Row>
    </Col>
  );
};

SmallItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    thumbnail: PropTypes.shape().isRequired,
    intro: PropTypes.string,
    meta: PropTypes.shape({
      first_published_at: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  withIntro: PropTypes.bool,
  vertical: PropTypes.bool,
};

SmallItem.defaultProps = {
  withIntro: true,
  vertical: true,
};

class BlogWidget extends React.Component {
  static propTypes = {
    posts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    withIntro: PropTypes.bool,
    vertical: PropTypes.bool,
  };

  static defaultProps = {
    withIntro: true,
    vertical: true,
  };

  render() {
    const { posts, withIntro, vertical } = this.props;
    const readyPosts = vertical ? posts : posts.slice(0, 2);
    return (
      <Panel className="mb-3">
        <Row>
          {readyPosts.map(item => (
            <SmallItem
              key={`bwidget_${item.id}`}
              item={item}
              withIntro={withIntro}
              vertical={vertical}
            />
          ))}
          <Col>
            <Link to={listUrl()}>
              <FormattedMessage
                id="blog.moreposts"
                defaultMessage="Більше новин тут"
                description=""
              />
            </Link>
          </Col>
        </Row>
      </Panel>
    );
  }
}

export default withStyles(s)(BlogWidget);
