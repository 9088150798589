import React from 'react';
import PropTypes from 'prop-types';

import { BANNER_IDS, AFFILIATE_ID } from './config';

const CarVerticalVinCheckLink = ({ vin, className, children }) => (
  <a
    className={className}
    target="_blank"
    rel="nofollow"
    href={`https://www.carvertical.com/ua/poperednja-perevirka?&utm_source=aff&a=${AFFILIATE_ID}&b=${
      BANNER_IDS.default
    }&vin=${vin}`}
  >
    {children}
  </a>
);

CarVerticalVinCheckLink.propTypes = {
  vin: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
};

CarVerticalVinCheckLink.defaultProps = {
  className: null,
};
export default CarVerticalVinCheckLink;
