import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import URI from 'urijs';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';

import history from '../../history';
import s from './SearchBar.css';

const onSubmit = values => {
  history.push(new URI('/search').search(values).toString());
};

const renderField = ({ input, placeholder }) => (
  <input
    {...input}
    type="text"
    placeholder={placeholder}
    className="form-control form-control-sm"
  />
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string.isRequired,
};

class SearchBar extends React.Component {
  static contextTypes = { fetch: PropTypes.func.isRequired };

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    // resolveSearchQuery: PropTypes.func.isRequired
    // searchBar: PropTypes.shape().isRequired
  };

  render() {
    return (
      <form className={s.form} onSubmit={this.props.handleSubmit(onSubmit)}>
        <FormattedMessage
          id="top.search_input.placeholder"
          defaultMessage="Наприклад"
          description=""
        >
          {msg => (
            <Field
              className="form-control"
              name="query"
              placeholder={`${msg}: bmw x6 2010`}
              component={renderField}
            />
          )}
        </FormattedMessage>
      </form>
    );
  }
}

export default withStyles(s)(
  reduxForm({
    form: 'searchBarForm',
  })(SearchBar),
);
