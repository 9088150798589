import { defineMessages } from 'react-intl';

export default defineMessages({
  'Інші марки на Avtoprod': {
    id: 'seo:modelPage:otherMakes',
    defaultMessage: 'Інші марки на Avtoprod',
  },
  'Інші моделі': {
    id: 'seo:modelPage:models',
    defaultMessage: 'Інші моделі',
  },
  '{make} {model} в інших регіонах України': {
    id: 'seo:modelPage:otherRegions',
    defaultMessage: '{make} {model} в інших регіонах України',
  },
});
