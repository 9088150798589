import { connect } from 'react-redux';

import { toggleMenu } from '../actions/app/menu';
import HeaderLogo from '../components/Header/HeaderLogo';

function mapStateToProps({ menu }) {
  return { menu };
}

export default connect(mapStateToProps, { toggleMenu })(HeaderLogo);
