exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ipUFr{position:absolute;z-index:1000;right:0;top:0}", ""]);

// exports
exports.locals = {
	"root": "ipUFr"
};