import React from 'react';
import PropTypes from 'prop-types';
import JsonLd from './JsonLd';
import thumbnail from '../ThumborImage/thumbor';

// TODO: add more keys for cars with modifications

const VehicleOffer = ({ vehicleOffer }) => {
  if (!vehicleOffer.id || !vehicleOffer.images) {
    return null;
  }

  const {
    title,
    make,
    model,
    body,
    year,
    fuel,
    gears_type: gearsType,
    price_currency: priceCurrency,
    price,
    mileage,
    engine_capacity: engineCapacity,
    images,
    active,
  } = vehicleOffer;

  const data = {
    '@context': 'http://schema.org',
    '@type': 'Vehicle',
    name: title,
    model: model.title,
    image: thumbnail(images[0].url, 640),
    ...(body ? { body: body.title } : null),
    productionDate: year,
    ...(fuel ? { fuelType: fuel.title } : null),
    ...(gearsType ? { vehicleTransmission: gearsType.title } : null),
    ...(engineCapacity
      ? {
          fuelCapacity: {
            '@type': 'QuantitativeValue',
            value: engineCapacity,
            unitCode: 'LTR',
          },
        }
      : null),
    ...(mileage
      ? {
          mileageFromOdometer: {
            '@type': 'QuantitativeValue',
            value: mileage * 1000,
            unitCode: 'KMT',
          },
        }
      : null),
    brand: {
      '@type': 'Brand',
      name: make.title,
    },
    offers: {
      '@type': 'Offer',
      priceCurrency,
      price,
      ...(active ? { availability: 'https://schema.org/InStock' } : null),
    },
  };

  return <JsonLd data={data} />;
};

VehicleOffer.propTypes = {
  vehicleOffer: PropTypes.shape(),
};

VehicleOffer.defaultProps = {
  vehicleOffer: {},
};

export default VehicleOffer;
