import React from 'react';

import Layout from 'components/Layout';
import MakePage from 'containers/Seo/MakePage';
import { carItemsRequest } from 'actions/carsActions';
import { setBreadcrumbs } from 'actions/app/breadcrumbs';
import { fetchModels } from 'actions/catalog/modelActions';
import { setModels, setSearchQuery } from 'actions/search/formActions';
import ogp from 'constants/ogp';
import { SITE_HOST } from 'constants/index';
import { makeLocalize } from 'locales';

import { getRegionUrl, getMakeUrl } from './urls';
import messages from './messages';

async function action({ store, fetch, make, region, path, intl }) {
  const localize = makeLocalize(intl, messages);
  const regionTitle = region ? ` ${region.title}` : '';
  const context = {
    make: make.title,
    region: regionTitle,
  };
  const title = localize(
    'Продаж легкових автомобілів {make}{region}.',
    context,
  );

  const description = localize(
    'Продаж легкових авто {make} б/в та нові{region}. Швидкий підбір авто. Актуальні ціни та фото. Технічні характеристики автомобілів {make}.',
    context,
  );

  store.dispatch(
    setBreadcrumbs([
      { title: 'Avtoprod', href: '/' },
      ...(region ? [{ title: region.title, href: getRegionUrl(region) }] : []),
      { title: make.title, href: getMakeUrl(make) },
      { title, active: true },
    ]),
  );

  const query = {
    make: make.id,
    ...(region ? { locations: region.id } : null),
  };

  const models = await fetchModels(fetch, make.id);
  store.dispatch(setModels(models));
  store.dispatch(setSearchQuery(query));

  const { cars } = await carItemsRequest(fetch, query);

  const pageOgp = {
    ...ogp,
    title,
    description,
    url: `${SITE_HOST}${path}`,
  };

  return {
    chunks: ['seo'],
    title,
    description,
    seo: { ogp: pageOgp },
    component: (
      <Layout>
        <MakePage cars={cars} region={region} make={make} title={title} />
      </Layout>
    ),
  };
}

export default action;
