import values from 'lodash/values';
import forEach from 'lodash/forEach';
import flattenDeep from 'lodash/flattenDeep';

import { fetchLocationsTree } from '../actions/locationActions';
import { setLocations, setMakes } from '../actions/search/formActions';
import { fetchMakes } from '../actions/catalog/makeActions';
import { fetchModels } from '../actions/catalog/modelActions';

const LOCATIONS_CACHE = {};

export async function getRegion(regionId, store, fetch, key = 'slug') {
  let {
    search_form: { locations },
  } = store.getState();
  if (locations.length === 0) {
    locations = await fetchLocationsTree(fetch);
    store.dispatch(setLocations(locations));
  }
  if (values(LOCATIONS_CACHE.length === 0)) {
    forEach(flattenDeep(locations), item => {
      LOCATIONS_CACHE[item[key]] = item;
      forEach(item.childrens, i => {
        LOCATIONS_CACHE[i[key]] = i;
      });
    });
  }
  return LOCATIONS_CACHE[regionId];
}

export async function isRegion(regionId, store, fetch, key = 'slug') {
  return Boolean(await getRegion(regionId, store, fetch, key));
}

const MAKES_CACHE = {};

export async function getMake(makeId, store, fetch, key = 'slug') {
  let {
    search_form: { makes },
  } = store.getState();
  if (makes.length === 0) {
    makes = await fetchMakes(fetch);
    store.dispatch(setMakes(makes));
  }
  if (values(MAKES_CACHE).length === 0) {
    forEach(makes, item => {
      MAKES_CACHE[item[key]] = item;
    });
  }
  return MAKES_CACHE[makeId];
}

export async function isMake(makeId, store, fetch, key = 'slug') {
  return Boolean(getMake(makeId, store, fetch, key));
}

const MODELS_CACHE = {};

export async function getModel(makeId, modelId, store, fetch, key = 'slug') {
  if (!MODELS_CACHE[makeId]) {
    const make = await getMake(makeId, store, fetch);
    const models = await fetchModels(fetch, make.id);
    const result = {};
    forEach(models, item => {
      result[item[key]] = item;
    });
    MODELS_CACHE[makeId] = result;
  }

  return MODELS_CACHE[makeId][modelId];
}

export async function isModel(makeId, modelId, store, fetch, key = 'slug') {
  return Boolean(getModel(makeId, modelId, store, fetch, key));
}
