import { defineMessages } from 'react-intl';

export default defineMessages({
  'Розміщення оголошення': {
    id: 'add_form:title',
    defaultMessage: 'Розміщення оголошення',
  },
  'Введіть інформацію про Ваше авто': {
    id: 'add_form:description:1',
    defaultMessage: 'Введіть інформацію про Ваше авто',
  },
  Марка: {
    id: 'add_form:make:label',
    defaultMessage: 'Марка',
  },
  'Оберіть марку': {
    id: 'add_form:make:ph',
    defaultMessage: 'Оберіть марку',
  },
  Модель: {
    id: 'add_form:model:label',
    defaultMessage: 'Модель',
  },
  'оберіть модель': {
    id: 'add_form:model:ph1',
    defaultMessage: 'оберіть модель',
  },
  'спочатку оберіть марку': {
    id: 'add_form:model:ph2',
    defaultMessage: 'спочатку оберіть марку',
  },
  'Рік виробництва': {
    id: 'add_form:year:label',
    defaultMessage: 'Рік виробництва',
  },
  оберіть: {
    id: 'add_form:emptyLabel',
    defaultMessage: 'оберіть',
  },
  Пробіг: {
    id: 'add_form:mileage:label',
    defaultMessage: 'Пробіг',
  },
  'тис. км.': {
    id: 'add_form:mileage:suffix',
    defaultMessage: 'тис. км.',
  },
  Пальне: {
    id: 'add_form:fuel:label',
    defaultMessage: 'Пальне',
  },
  'оберіть тип пального': {
    id: 'add_form:fuel:ph',
    defaultMessage: 'оберіть тип пального',
  },
  "Об'єм двигуна": {
    id: 'add_form:engine_capacity:label',
    defaultMessage: "Об'єм двигуна",
  },
  'л.': {
    id: 'add_form:engine_capacity:siffix',
    defaultMessage: 'л.',
  },
  'Коробка передач': {
    id: 'add_form:gearbox:label',
    defaultMessage: 'Коробка передач',
  },
  Привід: {
    id: 'add_form:drive:label',
    defaultMessage: 'Привід',
  },
  Кузов: {
    id: 'add_form:body:label',
    defaultMessage: 'Кузов',
  },
  Колір: {
    id: 'add_form:color:label',
    defaultMessage: 'Колір',
  },
  Кредит: {
    id: 'add_form:kredit:label',
    defaultMessage: 'Кредит',
  },
  'Додаткова інформація про авто': {
    id: 'add_form:description:label',
    defaultMessage: 'Додаткова інформація про авто',
  },
  'Вкажіть контактну інформацію': {
    id: 'add_form:contacts:heading',
    defaultMessage: 'Вкажіть контактну інформацію',
  },
  'Місто або регіон': {
    id: 'add_form:locations:label',
    defaultMessage: 'Місто або регіон',
  },
  'Додайте фото': {
    id: 'add_form:photo:add:heading',
    defaultMessage: 'Додайте фото',
  },
  'Зберегти зміни': {
    id: 'add_form:save_changes',
    defaultMessage: 'Зберегти зміни',
  },
  'Розмістити безкоштовно': {
    id: 'add_form:add_free',
    defaultMessage: 'Розмістити безкоштовно',
  },
  Продовжити: {
    id: 'add_form:sell_fast',
    defaultMessage: 'Продовжити',
  },
  'Вкажіть, будь-ласка, марку': {
    id: 'add_form:errors:make',
    defaultMessage: 'Вкажіть, будь-ласка, марку',
  },
  'Пробіг не може бути більше шести знаків': {
    id: 'add_form:errors:mileage',
    defaultMessage: 'Пробіг не може бути більше шести знаків',
  },
  'Вкажіть, будь-ласка, модель': {
    id: 'add_form:errors:model',
    defaultMessage: 'Вкажіть, будь-ласка, модель',
  },
  'Максимально 3 знаки до крапки та 2 після.': {
    id: 'add_form:errors:engine',
    defaultMessage: 'Максимально 3 знаки до крапки та 2 після.',
  },
  'Вкажіть, будь-ласка, рік виробництва': {
    id: 'add_form:errors:year',
    defaultMessage: 'Вкажіть, будь-ласка, рік виробництва',
  },
  'Вкажіть, будь-ласка, місто або регіон': {
    id: 'add_form:errors:region',
    defaultMessage: 'Вкажіть, будь-ласка, місто або регіон',
  },
  'Вкажіть, будь-ласка, ціну': {
    id: 'add_form:errors:price',
    defaultMessage: 'Вкажіть, будь-ласка, ціну',
  },
  'Ціна не може бути менше нуля': {
    id: 'add_form:errors:priceLzero',
    defaultMessage: 'Ціна не може бути менше нуля',
  },
  'Ціна не може бути більше семи знаків': {
    id: 'add_form:errors:price:length',
    defaultMessage: 'Ціна не може бути більше семи знаків',
  },
  'Вкажіть, будь-ласка, правильний номер телефону': {
    id: 'phones_field:error',
    defaultMessage: 'Вкажіть, будь-ласка, правильний номер телефону',
  },
  'Необхідно хочаб {qty} телефон': {
    id: 'phones_field:error:qty',
    defaultMessage: 'Необхідно хочаб {qty} телефон',
  },
  Двигун: {
    id: 'car:item:tech:engine',
    defaultMessage: 'Двигун',
  },
  КПП: {
    id: 'car:item:tech:kpp',
    defaultMessage: 'КПП',
  },
  'вкажіть переваги авто, особливості використання, додаткове обладнання, умови продажу, тощо. Оголошення з детальним описом привертають більше уваги потенційних покупців ніж оголошення без опису': {
    id: 'car:item:description:helptext',
    defaultMessage:
      'вкажіть переваги авто, особливості використання, додаткове обладнання, умови продажу, тощо. Оголошення з детальним описом привертають більше уваги потенційних покупців ніж оголошення без опису',
  },
  'завантажуйте багато фото без обмежень, перетягуйте фото, щоб визначити порядок відображення': {
    id: 'car:item:images:helptext',
    defaultMessage:
      'завантажуйте багато фото без обмежень, перетягуйте фото, щоб визначити порядок відображення',
  },
});
