import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Panel from 'components/Helpers/Panel';
import Links from 'components/Catalog/Links';
import { getRegionUrl } from 'routes/seo/urls';

const Regions = ({ locations }) => (
  <Panel>
    <p className="h4">
      <FormattedMessage
        id="home.regions.heading"
        defaultMessage="Регіони"
        description=""
      />
    </p>
    <hr className="my-2" />
    <Links catalogItems={locations} link={item => getRegionUrl(item)} />
  </Panel>
);

Regions.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Regions;
