import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Card, CardBody } from 'reactstrap';
// import _ from 'utils';

import Links from '../../Catalog/Links';
import s from './AllMakes.css';
import { getMakeUrl } from '../../../routes/seo/urls';
// import { POPULAR_MAKES } from '../../Home/constants';

class AllMakes extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    makes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    linkUrl: PropTypes.func,
  };

  static defaultProps = {
    linkUrl: item => getMakeUrl(item),
  };

  render() {
    const { makes, title, linkUrl } = this.props;

    return (
      <Card className={`${s.links} ${s.allMakes}`}>
        <CardBody>
          <p className="h4">{title}</p>
          <hr className="my-2" />
          <Links catalogItems={makes} link={linkUrl} columns={6} />
        </CardBody>
      </Card>
    );
  }
}

export default withStyles(s)(AllMakes);
