import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { instagram } from 'react-icons-kit/fa/instagram';
import { facebookSquare } from 'react-icons-kit/fa/facebookSquare';
import { telegram } from 'react-icons-kit/fa/telegram';
import { Icon } from 'react-icons-kit';
import { FormattedMessage } from 'react-intl';
import s from './Links.css';

const FACEBOOK_HOME = 'https://www.facebook.com/avtoprodcom/';
const INSTAGRAM_HOME = 'https://www.instagram.com/avtoprod.ua/';

const DefaultLink = withStyles(s)(
  ({ to, withLabel, icon, message, className }) => (
    <a
      href={to}
      target="_blank"
      className={`${s.root} ${className || ''}`}
      rel="noreferrer"
    >
      {icon} {withLabel && message}
    </a>
  ),
);

DefaultLink.propTypes = {
  to: PropTypes.string.isRequired,
  withLabel: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  className: PropTypes.string,
};

DefaultLink.defaultProps = {
  withLabel: true,
  className: null,
};

export const InstagramLink = withStyles(s)(({ withLabel, size, ...props }) => (
  <DefaultLink
    to={INSTAGRAM_HOME}
    withLabel={withLabel}
    icon={<Icon className={s.instagram} icon={instagram} size={size} />}
    message={
      <FormattedMessage
        id="links.instagram.label"
        defaultMessage="Instagram"
        description=""
      />
    }
    {...props}
  />
));

InstagramLink.propTypes = {
  withLabel: PropTypes.bool,
  size: PropTypes.number,
};

InstagramLink.defaultProps = {
  withLabel: true,
  size: 24,
};

export const FacebookLink = withStyles(s)(({ withLabel, size, ...props }) => (
  <DefaultLink
    to={FACEBOOK_HOME}
    withLabel={withLabel}
    icon={<Icon className={s.facebook} icon={facebookSquare} size={size} />}
    message={
      <FormattedMessage
        id="links.facebook.label"
        defaultMessage="Facebook"
        description=""
      />
    }
    {...props}
  />
));

FacebookLink.propTypes = {
  withLabel: PropTypes.bool,
  size: PropTypes.number,
};

FacebookLink.defaultProps = {
  withLabel: true,
  size: 24,
};

export const TelegramLink = withStyles(s)(({ withLabel, size, ...props }) => (
  <DefaultLink
    to="https://t.me/avtoprod_ua"
    withLabel={withLabel}
    icon={<Icon className={s.telegram} icon={telegram} size={size} />}
    message={
      <FormattedMessage
        id="links.telegram.label"
        defaultMessage="Ми в Телеграм"
        description=""
      />
    }
    {...props}
  />
));

TelegramLink.propTypes = {
  withLabel: PropTypes.bool,
  size: PropTypes.number,
};

TelegramLink.defaultProps = {
  withLabel: true,
  size: 24,
};
