import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import { ic_directions_car as icDirectionsCar } from 'react-icons-kit/md/ic_directions_car';
// import { ic_attach_money as icAttachMoney } from 'react-icons-kit/md/ic_attach_money';
// import { ic_apps as icApps } from 'react-icons-kit/md/ic_apps';
// import { ic_account_circle as icAccountCircle } from 'react-icons-kit/md/ic_account_circle';
// import { ic_search as icSearch } from 'react-icons-kit/md/ic_search';
// import { ic_insert_chart as icInsertChart } from 'react-icons-kit/md/ic_insert_chart';
import { checkSquareO } from 'react-icons-kit/fa/checkSquareO';
import { ic_local_gas_station as icLocalGasStation } from 'react-icons-kit/md/ic_local_gas_station';
import { ic_account_balance_wallet as icAccountBalanceWallet } from 'react-icons-kit/md/ic_account_balance_wallet';
import { pencilSquare } from 'react-icons-kit/fa/pencilSquare';
import { reorder } from 'react-icons-kit/fa/reorder';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';

import HeaderLogo from '../../containers/HeaderLogo';
import AccountInfo from '../../containers/AccountInfo';
import { InstagramLink, TelegramLink } from '../Helpers/Links';
import HelpZsu from '../Helpers/HelpZsu';
import Link from '../Link';
import { CarVerticalLink, CHANNELS } from '../CarVertical';

import s from './Navigation.css';

class Navigation extends React.Component {
  static propTypes = {
    menu: PropTypes.shape({
      show: PropTypes.bool.isRequired,
    }).isRequired,
  };

  render() {
    const hiddenClass = this.props.menu.show ? '' : s.hidden;

    return (
      <div className={`${hiddenClass} ${s.root}`} role="navigation">
        <div className={s.navLogo}>
          <HeaderLogo />
        </div>
        <nav className="nav flex-column">
          <AccountInfo />
          <hr />
          <HelpZsu className={`nav-link ${s.link}`} />
          <hr />
          <Link className={`nav-link ${s.link}`} to="/cars">
            <Icon icon={icDirectionsCar} size={24} />
            <span className={s.menu_text}>
              <FormattedMessage
                id="nav.cars.text"
                defaultMessage="Легкові"
                description="Легкові в меню"
              />
            </span>
          </Link>
          {/* <Link className={`nav-link ${s.link}`} to="/avtosalons">
              <Icon icon={icAttachMoney} size={24}/>
              <span className={s.menu_text}>Автосалони</span>
            </Link> */}
          <hr />
          <Link className={`nav-link ${s.link}`} to="/dashboard/cars">
            <Icon icon={reorder} size={24} />
            <span className={s.menu_text}>
              <FormattedMessage
                id="nav.adverts.text"
                defaultMessage="Мої оголошення"
                description="Мої оголошення в меню"
              />
              {/* //  &nbsp;<span className="badge badge-secondary">5</span> */}
            </span>
          </Link>
          {/* <Link className={`nav-link ${s.link}`} to="/profile">
            <Icon icon={icAccountCircle} size={24} />
            <span className={s.menu_text}>
              <FormattedMessage
                id="nav.profile.text"
                defaultMessage="Мій профіль"
                description="Мій профіль в меню"
              />
            </span>
          </Link>
          <Link className={`nav-link ${s.link}`} to="/dashboard">
            <Icon icon={icApps} size={24} />
            <span className={s.menu_text}>
              <FormattedMessage
                id="nav.dashboard.text"
                defaultMessage="Панель керування"
                description="Панель керування в меню"
              />
            </span>
          </Link>
          <Link className={`nav-link ${s.link}`} to="/statistics">
            <Icon icon={icInsertChart} size={24} />
            <span className={s.menu_text}>
              <FormattedMessage
                id="nav.statistics.text"
                defaultMessage="Статистика"
                description="Статистика в меню"
              />
            </span>
          </Link> */}
          <Link className={`nav-link ${s.link}`} to="/payment">
            <Icon icon={icAccountBalanceWallet} size={24} />
            <span className={s.menu_text}>
              <FormattedMessage
                id="nav.payments.text"
                defaultMessage="Оплати"
                description="Оплати в меню"
              />
            </span>
          </Link>
          <hr />
          <Link className={`nav-link ${s.link}`} to="/pdr/">
            <img src="/learning_pdr.svg" alt="PDR" className={s.pdr_icon} />
            <span className={s.menu_text}>
              <FormattedMessage
                id="nav.pdr.text"
                defaultMessage="ПДР"
                description="ПДР в меню"
              />{' '}
              {new Date().getFullYear()}
            </span>
          </Link>
          <hr />
          <Link className={`nav-link ${s.link}`} to="/blog">
            <Icon icon={pencilSquare} size={24} />
            <span className={s.menu_text}>
              <FormattedMessage
                id="nav.blog.text"
                defaultMessage="Блог"
                description="Блог в меню"
              />
            </span>
          </Link>
          <Link className={`nav-link ${s.link}`} to="/fuel/">
            <Icon icon={icLocalGasStation} size={24} />
            <span className={s.menu_text}>
              <FormattedMessage
                id="nav.fuel.text"
                defaultMessage="Пальне"
                description=""
              />
            </span>
          </Link>
          <CarVerticalLink
            className={`nav-link ${s.link}`}
            version="v3"
            channel={CHANNELS.NAVIGATION}
          >
            <Icon icon={checkSquareO} size={24} />
            <span className={s.menu_text}>
              <FormattedMessage
                id="nav.carvertical.text"
                defaultMessage="Перевірка авто за VIN кодом"
                description=""
              />
            </span>
          </CarVerticalLink>
          <hr />
          <InstagramLink className={`nav-link ${s.link} ${s.instagram}`} />
          <TelegramLink className={`nav-link ${s.link} ${s.instagram}`} />
          {/* <Link className={`nav-link ${s.link}`} to="/contact">
            <Icon icon={icSearch} size={24}/>
            <span className={s.menu_text}>Mazda 3 2008</span>
          </Link>
          <Link className={`nav-link ${s.link}`} to="/contact">
            <Icon icon={icSearch} size={24}/>
            <span className={s.menu_text}>BMW X3 2017 дизель</span>
          </Link> */}
        </nav>
      </div>
    );
  }
}

export default withStyles(s)(Navigation);
