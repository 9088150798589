import ThumborJS from 'thumbor';
import URL from 'urijs';

const THUMBOR_KEY = 'avtodata.net:dev:key';
const THUMBOR_SERVER = 'https://t.avtodata.net';

const tugen = new ThumborJS(THUMBOR_KEY, THUMBOR_SERVER);

function thumbnail(imageUrl, width = 150, height = 0) {
  const url = new URL(imageUrl);
  return tugen
    .setImagePath(`${url.hostname()}${url.path()}`)
    .resize(width, height)
    .buildUrl();
}

export default thumbnail;
