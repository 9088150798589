import React from 'react';
import LazyLoad from 'react-lazyload';
import ThumborImage from './ThumborImage';

const LAZY_ENABLED = true;

const ImageComponent = ({ lazyProps = {}, ...props }) => {
  if (LAZY_ENABLED) {
    return (
      <LazyLoad once offset={100} throttle {...lazyProps}>
        <ThumborImage {...props} />
      </LazyLoad>
    );
  }
  return <ThumborImage {...props} />;
};

export default ImageComponent;
