exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1RJ-1{height:40px}", ""]);

// exports
exports.locals = {
	"root": "_1RJ-1",
	"full": "CD5R8",
	"mono": "_1DRot"
};