import React from 'react';
import Icon from 'react-icons-kit';
import { phone } from 'react-icons-kit/fa/phone';
import { mobile } from 'react-icons-kit/fa/mobile';
import { telegram } from 'react-icons-kit/fa/telegram';

export const phones = [
  {
    tel: '+380631234932',
    title: '+38(063) 1234-932',
    icon: <Icon icon={phone} />,
  },
  {
    tel: '+380681234932',
    title: '+38(068) 1234-932',
    icon: (
      <span>
        <Icon icon={mobile} />{' '}
        <Icon icon={telegram} style={{ color: '#08c' }} />
      </span>
    ),
  },
];

export const emails = ['help@avtoprod.ua'];
