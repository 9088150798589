exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3yvgR{margin-bottom:32px;margin-bottom:2rem}._1QJWO{margin-bottom:16px;margin-bottom:1rem}._1QJWO a{font-size:.85em}", ""]);

// exports
exports.locals = {
	"root": "_3yvgR",
	"links": "_1QJWO"
};