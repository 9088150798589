import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Icon from 'react-icons-kit';
import { cameraRetro } from 'react-icons-kit/fa/cameraRetro';
import { FormattedMessage } from 'react-intl';

import s from './NoPhoto.css';

const NoPhoto = props => (
  <div className={`img-thumbnail ${s.root} ${props.className}`}>
    <div>
      <Icon icon={cameraRetro} size={28} />
    </div>
    <div>
      <FormattedMessage
        id="image.nophoto"
        defaultMessage="Немає фото"
        description=""
      />
    </div>
  </div>
);

NoPhoto.propTypes = {
  className: PropTypes.string,
};

NoPhoto.defaultProps = {
  className: '',
};

export default withStyles(s)(NoPhoto);
