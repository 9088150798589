const fromToValidation = (values, from, to) =>
  values[from] &&
  values[to] &&
  parseInt(values[from], 10) >= parseInt(values[to], 10);

const validate = values => {
  const errors = {};
  if (values.year_t && values.year_f && values.year_t <= values.year_f) {
    errors.year_t = 'це значення не може бути менше за попереднє';
  }
  if (fromToValidation(values, 'price_f', 'price_t')) {
    errors.price_t = 'не корректне значення';
  }

  if (fromToValidation(values, 'mileage_f', 'mileage_t')) {
    errors.mileage_t = 'не корректне значення';
  }
  return errors;
};

export default validate;
