import React from 'react';
import URI from 'urijs';
import PropTypes from 'prop-types';
import { AFFILIATE_ID, BANNER_IDS, LANDING_PAGES } from './config';

const renderLink = ({ version, channel = null }) =>
  new URI(LANDING_PAGES[version]).search({
    a: AFFILIATE_ID,
    b: BANNER_IDS[version],
    ...(channel ? { chan: channel } : null),
  });

const CarVerticalLink = ({ version = 'v3', channel, children, className }) => (
  <a
    className={className}
    href={renderLink({ version, channel })}
    rel="nofollow"
    target="_blank"
  >
    {children}
  </a>
);

CarVerticalLink.propTypes = {
  version: PropTypes.string,
  channel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

CarVerticalLink.defaultProps = {
  version: 'v3',
  className: null,
};
export default CarVerticalLink;
