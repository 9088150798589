import ogp from 'constants/ogp';
import { SITE_HOST } from 'constants/index';

export function getTitle({ localize }) {
  return localize(
    'Avtoprod.ua - Купити та продати авто. Найбільший авторинок України.',
  );
}

export function getDescription({ localize }) {
  return localize(
    'Avtoprod.ua - вигідний продаж та легка купівля Б/В та нового авто в Україні. Тільки на avtoprod.ua автобазар реальних продавців авто.',
  );
}

export function seoItemOgp({ localize }) {
  return {
    ...ogp,
    type: 'article',
    title: getTitle({ localize }),
    description: getDescription({ localize }),
    url: SITE_HOST,
    // ...(thumbnail && thumbnail.url ? { image: thumbnail.url } : null ),
  };
}
