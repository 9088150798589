import { connect } from 'react-redux';

import Makes from '../../components/Home/Makes';
import { POPULAR_MAKES } from '../../components/Home/constants';

function mapStateToProps({ search_form: { makes } }) {
  return { makes: makes.filter(i => POPULAR_MAKES.includes(i.slug)) };
}

export default connect(mapStateToProps)(Makes);
