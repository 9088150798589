import React from 'react';
// import PropTypes from 'prop-types';
import JsonLd from './JsonLd';

const WebSite = () => {
  const data = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: 'https://avtoprod.ua',
    name: 'Avtoprod.ua',
    // "logo": "https://auto.ria.com/images/autoria-seo.png",
    // "sameAs": [
    //     "http://www.facebook.com/AUTORIAcom",
    //     "https://twitter.com/AUTO_RIA_com",
    //     "https://plus.google.com/+&#1072;&#1074;&#1090;&#1086;&#1088;&#1080;&#1072;",
    //     "https://www.youtube.com/user/AutoRiaUA",
    //     "https://www.instagram.com/auto_ria_com/"
    // ],
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+38-063-1234-932',
        contactType: 'customer service',
        areaServed: 'UA',
        availableLanguage: ['Ukrainian', 'Russian'],
      },
      {
        '@type': 'ContactPoint',
        telephone: '+38-068-1234-932',
        contactType: 'customer service',
        areaServed: 'UA',
        availableLanguage: ['Ukrainian', 'Russian'],
      },
    ],
  };

  return <JsonLd data={data} />;
};

export default WebSite;
