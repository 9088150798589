import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import s from 'react-select/dist/react-select.css';
import rs from './renderers.css';

export const renderField = ({
  input,
  label,
  type,
  meta: { touched, error },
  placeholder,
  required,
  disabled,
}) => {
  const hasError = touched && error;
  const ph = placeholder || label;

  return (
    <FormGroup>
      {label && <span>{label}</span>}
      {required && label && <span className="text-danger">*</span>}
      <div>
        <Input
          {...input}
          type={type}
          placeholder={ph}
          className={hasError ? ' is-invalid' : ''}
          disabled={disabled}
        />
        {hasError && <small className="text-danger">{error}</small>}
      </div>
    </FormGroup>
  );
};

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

renderField.defaultProps = {
  label: '',
  type: 'text',
  placeholder: '',
  required: false,
  disabled: false,
};

const cleanValue = (option, method) => {
  let value = '';
  if (option && option.value) {
    value = option.value;
  }
  method(value);
};

export const renderSelect = withStyles(s)(
  ({
    input,
    label,
    options = [],
    required,
    disabled,
    emptyLabel,
    meta: { touched, error },
    noResultsText,
  }) => {
    const hasError = touched && error;
    const empty = emptyLabel || label;
    return (
      <FormGroup>
        {label && <span>{label}</span>}
        {required && label && <span className="text-danger">*</span>}
        <div>
          <Select
            value={input.value}
            onChange={o => cleanValue(o, input.onChange)}
            onBlur={() => input.onBlur(input.value)}
            onFocus={input.onFocus}
            options={options}
            className={`${hasError ? ' is-invalid' : ''}`}
            placeholder={empty}
            onBlurResetsInput={false}
            disabled={disabled}
            noResultsText={noResultsText}
          />
          {hasError && <small className="text-danger">{error}</small>}
        </div>
      </FormGroup>
    );
  },
);

renderSelect.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  emptyLabel: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  noResultsText: PropTypes.string,
};

renderSelect.defaultProps = {
  options: [],
  emptyLabel: '',
  label: '',
  required: false,
  disabled: false,
  noResultsText: 'нічого не знайдено',
};

export const renderTextarea = ({
  input,
  label,
  required,
  meta: { touched, error },
}) => {
  const hasError = touched && error;
  return (
    <FormGroup>
      {label && <span>{label}</span>}
      {required && label && <span className="text-danger">*</span>}
      <div>
        <Input
          type="textarea"
          {...input}
          className={hasError ? ' is-invalid' : ''}
        />
        {hasError && <small className="text-danger">{error}</small>}
      </div>
    </FormGroup>
  );
};

renderTextarea.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool,
};

renderTextarea.defaultProps = {
  label: '',
  required: false,
};

export const renderAddonField = withStyles(rs)(
  ({
    input,
    label,
    type,
    meta: { touched, error },
    placeholder,
    suffix,
    prefix,
    required,
  }) => {
    const hasError = touched && error;
    const ph = placeholder || label;
    return (
      <div>
        {label && <span>{label}</span>}
        {required && label && <span className="text-danger">*</span>}
        <InputGroup className="mb-3">
          {prefix && (
            <InputGroupAddon addonType="prepend">{prefix}</InputGroupAddon>
          )}
          <Input
            {...input}
            type={type}
            placeholder={ph}
            className={`${rs.addon_input} ${hasError ? ' is-invalid' : ''}`}
          />
          {suffix && (
            <InputGroupAddon addonType="append">{suffix}</InputGroupAddon>
          )}
        </InputGroup>
        {hasError && <small className="text-danger">{error}</small>}
      </div>
    );
  },
);

renderAddonField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  placeholder: PropTypes.string,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  required: PropTypes.bool,
};

renderAddonField.defaultProps = {
  label: '',
  type: 'text',
  placeholder: '',
  suffix: undefined,
  prefix: undefined,
  required: false,
};
