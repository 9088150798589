import PropTypes from 'prop-types';
import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Icon from 'react-icons-kit';
// import { refresh } from 'react-icons-kit/fa/refresh';
import { lineChart } from 'react-icons-kit/fa/lineChart';
import { ellipsisV } from 'react-icons-kit/fa/ellipsisV';
import { useStatisticComponent } from 'components/Statistics/hooks';

import s from './ActionMenu.css';

const ActionMenu = ({ car, auth, fetch }) => {
  if (auth.userId !== 1) {
    return null;
  }

  const [dropdownOpen, setOpen] = React.useState(false);
  const [Statistics, setStatsVisible] = useStatisticComponent();

  const toggle = () => setOpen(!dropdownOpen);

  return (
    <div className={s.root}>
      <ButtonDropdown
        isOpen={dropdownOpen}
        size="sm"
        direction="left"
        toggle={toggle}
      >
        <DropdownToggle>
          <Icon icon={ellipsisV} size={16} />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>Доступні дії</DropdownItem>
          <DropdownItem onClick={() => setStatsVisible(true)}>
            <Icon icon={lineChart} size={16} /> <span>Статистика</span>
            <Statistics car={car} setVisible={setStatsVisible} fetch={fetch} />
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

ActionMenu.propTypes = {
  auth: PropTypes.shape({
    userId: PropTypes.number,
  }).isRequired,
  car: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  fetch: PropTypes.func.isRequired,
};

export default withStyles(s)(ActionMenu);
