import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Icon from 'react-icons-kit';
import { thumbsUp } from 'react-icons-kit/fa/thumbsUp';
import cn from 'classnames';

import { currencies as CURRENCIES } from '../../../constants/currencies';

import s from './Price.css';

const formatPrice = (price, currency = 'USD') => {
  const formatted = `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return `${formatted} ${CURRENCIES[currency]}`;
};

const Price = ({
  price,
  currency = 'USD',
  className = null,
  best = false,
  isSuper = false,
  priceUsd = null,
  priceUah = null,
}) => (
  <div className={cn(className, s.root)}>
    <div className={s.price}>
      {price === 0 ? (
        <span className={s.zero_price}>ціна не вказана</span>
      ) : (
        <>
          {formatPrice(price, currency)}
          {currency === 'USD' && priceUah && (
            <div className={s.alter_price}>{formatPrice(priceUah, 'UAH')}</div>
          )}
          {currency === 'UAH' && priceUsd && (
            <div className={s.alter_price}>{formatPrice(priceUsd, 'USD')}</div>
          )}
        </>
      )}

      {price !== 0 && (
        <>
          {best && (
            <div className={s.best}>
              <Icon icon={thumbsUp} size={18} /> краща ціна{' '}
            </div>
          )}
          {isSuper && (
            <div className={s.best}>
              <Icon icon={thumbsUp} size={18} /> супер ціна{' '}
            </div>
          )}
        </>
      )}
    </div>
  </div>
);

Price.propTypes = {
  price: PropTypes.number.isRequired,
  currency: PropTypes.string,
  className: PropTypes.string,
  best: PropTypes.bool,
  isSuper: PropTypes.bool,
  priceUah: PropTypes.number,
  priceUsd: PropTypes.number,
};

Price.defaultProps = {
  currency: 'USD',
  className: null,
  best: false,
  isSuper: false,
  priceUah: null,
  priceUsd: null,
};

export default withStyles(s)(Price);
