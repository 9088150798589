import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';

import Link from 'components/Link';
import Navigation from 'containers/Navigation';
import SearchBar from 'containers/SearchBar';
import AccountMenu from 'containers/AccountMenu';
import HeaderLogo from 'containers/HeaderLogo';

import s from './Header.css';

// const PromotionLine = () => (
//   <div className={s.promotionLine}>
//     50% знижки на всі послуги, акція дійсна до 10.04
//   </div>
// );

class Header extends React.Component {
  static contextTypes = { store: PropTypes.object.isRequired };

  render() {
    return (
      <div className={` mb-3 ${s.root}`}>
        {/* <PromotionLine /> */}
        <div className={`col-sm-12 ${s.container}`}>
          <div className="row">
            <div className={`col-sm-12 col-lg-5 ${s.left}`}>
              <HeaderLogo />
              <div className="mr-1 ml-1">
                <Link
                  className="btn btn-light btn-sm d-none d-md-inline"
                  to="/cars"
                >
                  <FormattedMessage
                    id="top.search.text"
                    defaultMessage="Знайти"
                    description="Кнопка знайти"
                  />
                </Link>
                &nbsp;
                <Link className="btn btn-success btn-sm" to="/add">
                  +{' '}
                  <FormattedMessage
                    id="top.add_button.add"
                    defaultMessage="Додати"
                    description=""
                  />{' '}
                  <span className="d-none d-xl-inline">
                    <FormattedMessage
                      id="top.add_button.advert"
                      defaultMessage="оголошення"
                      description=""
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className={`col-md-3 col-sm-12 ${s.search} d-none d-lg-flex`}>
              <SearchBar />
            </div>
            <div className={`col-md-4 col-sm-12 ${s.right} d-none d-lg-flex`}>
              <AccountMenu />
            </div>
          </div>
          <Navigation />
          {/* <div className={s.banner}>
            <h1 className={s.bannerTitle}>React</h1>
            <p className={s.bannerDesc}>Complex web apps made easy</p>
          </div> */}
        </div>
      </div>
    );
  }
}

export default withStyles(s)(Header);
