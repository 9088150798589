import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import CarItem from 'components/CarItem';
import s from './CarsList.css';

class CarsList extends React.Component {
  static propTypes = {
    cars: PropTypes.shape().isRequired,
  };

  render() {
    const { cars } = this.props;

    if (cars && cars.results && cars.results.length > 0) {
      return (
        <div className={s.root}>
          {cars.results.map(car => <CarItem key={car.id} car={car} />)}
        </div>
      );
    }

    return (
      <Alert color="warning">
        <FormattedMessage
          id="seo.carsList.empty"
          defaultMessage="Нажаль, нічого не знайдено. Скористайтесь формою пошуку щоб уточнити параметри."
          description=""
        />
      </Alert>
    );
  }
}

export default withStyles(s)(CarsList);
