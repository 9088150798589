import split from 'lodash/split';
import last from 'lodash/last';
import isNumber from 'lodash/isNumber';

import { BLOG_PER_PAGE } from '../../constants/blog';

const SEPARATOR = '__';

export const itemSlugUrl = slug => `/blog/${slug}.html`;

export const itemUrl = item => itemSlugUrl(item.meta.slug);

export const listUrl = () => `/blog`;

export const resolveId = slug => {
  const id = Number(split(last(split(slug, SEPARATOR)), '.')[0]);
  return isNumber(id) ? id : null;
};

export const resolveSlug = slug =>
  split(slug.replace('.html', ''), SEPARATOR)[0];

export const resolvePage = page => ({
  limit: BLOG_PER_PAGE,
  offset: page <= 1 ? 0 : (page - 1) * BLOG_PER_PAGE,
});
