import split from 'lodash/split';
import last from 'lodash/last';

const SEPARATOR = '__';

export const itemUrl = item => {
  if (item.id) {
    // return `/car/${item.meta.slug}${SEPARATOR}${item.id}.html`
    return `/car/${item.id}`;
  }
  return `/car/${item}`;
};

export const listUrl = () => `/cars`;

export const resolveId = slug => split(last(split(slug, SEPARATOR)), '.')[0];
