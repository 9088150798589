import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col, Container } from 'reactstrap';
import Icon from 'react-icons-kit';
import { envelopeO } from 'react-icons-kit/fa/envelopeO';
import { ccVisa } from 'react-icons-kit/fa/ccVisa';
import { FormattedMessage } from 'react-intl';

import { phones } from '../../constants/support';
import { getUrl as pageUrl } from '../../routes/infopages/urls';
import { listUrl } from '../../routes/blog/urls';
import { InstagramLink, TelegramLink } from '../Helpers/Links';
import LanguageSwitcher from '../LanguageSwitcher';
import Logo from '../Logo';
import Link from '../Link';
import { CarVerticalLink, CHANNELS } from '../CarVertical';

import s from './Footer.css';

class Footer extends React.Component {
  render() {
    return (
      <footer className={`footer ${s.root}`}>
        <Container>
          <Row className="mb-3">
            <Col md="4" xs="12">
              <Link className={s.footer_logo} to="/">
                <Logo full alt="Автопрод" className={s.logo} />
              </Link>
              <LanguageSwitcher />
            </Col>
            <Col>
              <Row>
                <Col xs="12" md="6" className="mb-2">
                  <div>
                    <small>
                      <FormattedMessage
                        id="footer.contacts.label"
                        defaultMessage="наші контакти"
                        description=""
                      />
                      :
                    </small>
                  </div>
                  <div>
                    <a className={s.help_email} href="mailto:help@avtoprod.ua">
                      <Icon icon={envelopeO} /> help@avtoprod.ua
                    </a>
                  </div>
                </Col>
                <Col xs="12" md="6">
                  {phones.map(({ tel, title, icon }) => (
                    <a className={s.phones} href={`tel:${tel}`} key={tel}>
                      {title} {icon}
                    </a>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md="4" xs="12">
              <ul>
                <li>
                  <Link to="/cars">
                    <FormattedMessage
                      id="footer.links.cars"
                      defaultMessage="Легкові автомобілі"
                      description=""
                    />
                  </Link>
                </li>
                {/* <li>
                  <Link to="/avtosalons" title="Автосалони на Автопрод">Автосалони</Link>
                </li> */}
                <li>
                  <Link to={listUrl()}>
                    <FormattedMessage
                      id="footer.links.blog"
                      defaultMessage="Блог"
                      description=""
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/fuel/">
                    <FormattedMessage
                      id="footer.fuel.kievobl"
                      defaultMessage="Ціни на пальне"
                      description=""
                    />
                  </Link>
                </li>
                <li>
                  <CarVerticalLink version="v3" channel={CHANNELS.FOOTER}>
                    Перевірка авто за VIN
                  </CarVerticalLink>
                </li>
              </ul>
            </Col>
            <Col md="4" xs="12">
              <ul>
                <li>
                  <Link to="/privacy">
                    <FormattedMessage
                      id="footer.links.rules"
                      defaultMessage="Умови використання"
                      description=""
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/pages/oferta">
                    <FormattedMessage
                      id="footer.links.oferta"
                      defaultMessage="Публічна оферта"
                      description=""
                    />
                  </Link>
                </li>
                {/* <li>
                  <Link to={pageUrl('adverts')}>
                    <FormattedMessage
                      id="footer.links.add.adverts"
                      defaultMessage="Розміщення оголошень"
                      description=""
                    />
                  </Link>
                </li>
                 <li>
                  <Link to={pageUrl('advertisement')} title="Розмішення реклами на сайті Автопрод">
                    Розміщенни реклами
                  </Link>
                </li> */}
                <li>
                  <Link to="/prodati/avto">
                    <FormattedMessage
                      id="footer.links.sell.car"
                      defaultMessage="Продати авто"
                      description=""
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/cars">
                    <FormattedMessage
                      id="footer.links.buy.car"
                      defaultMessage="Купити авто"
                      description=""
                    />
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md="4" xs="12">
              <ul>
                <li>
                  <Link to={pageUrl('about')}>
                    <FormattedMessage
                      id="footer.links.about"
                      defaultMessage="Про нас"
                      description=""
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/cars">
                    <FormattedMessage
                      id="footer.links.all.adverts"
                      defaultMessage="Усі оголошення"
                      description=""
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/kiyivska-oblast">
                    <FormattedMessage
                      id="footer.links.all.adverts.kiev"
                      defaultMessage="Продаж авто у Києві"
                      description=""
                    />
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className={s.since}>
                <div>
                  {new Date().getFullYear()} © Avtoprod.ua -{' '}
                  <FormattedMessage
                    id="footer.links.all.copyright"
                    defaultMessage="Всі права захищені"
                    description=""
                  />
                  .
                </div>
                <div>
                  <img
                    className={s.mc_logo}
                    src="/mc_logo.svg"
                    alt="mastercard"
                  />
                  <Icon className={s.visa_logo} size={40} icon={ccVisa} />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className={s.socials}>
              <InstagramLink withLabel={false} size={37} />
              <TelegramLink withLabel={false} size={40} className="ml-2" />
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default withStyles(s)(Footer);
