import React from 'react';
import Home from 'components/Home';
import Layout from 'components/Layout';
import { makeLocalize } from 'locales';
import { setBreadcrumbs } from 'actions/app/breadcrumbs';
import * as makeActions from 'actions/catalog/makeActions';
import { fetchCatalog } from 'actions/catalog/actions';
import * as formActions from 'actions/search/formActions';
import { fetchLocationsTree } from 'actions/locationActions';
import { setPayedCars } from 'actions/app/home';
import { payedCarsItemsRequest } from 'actions/carsActions';
import {
  homeBlogsItemsRequest,
  setBlogsToHomePage,
} from 'actions/blogsActions';
import { SERVICES_ON_HOME_SLUG } from 'constants/services';

import { getDescription, getTitle, seoItemOgp } from './seo';
import messages from './messages';

async function action({ store, fetch, intl, isInitialRender }) {
  const localize = makeLocalize(intl, messages);

  if (!isInitialRender) {
    store.dispatch(setBreadcrumbs([]));
    const [
      locations,
      makes,
      fuels,
      body,
      gearsTypes,
      carsResponse,
      blogs,
    ] = await Promise.all([
      fetchLocationsTree(fetch),
      makeActions.fetchMakes(fetch),
      fetchCatalog(fetch, 'fuel'),
      fetchCatalog(fetch, 'body'),
      fetchCatalog(fetch, 'gears_type'),
      payedCarsItemsRequest(fetch, SERVICES_ON_HOME_SLUG, 2, true),
      homeBlogsItemsRequest(fetch),
    ]);

    store.dispatch(formActions.setLocations(locations));
    store.dispatch(formActions.setMakes(makes));
    store.dispatch(formActions.setFuel(fuels));
    store.dispatch(formActions.setBody(body));
    store.dispatch(formActions.setGearsType(gearsTypes));
    store.dispatch(setPayedCars(carsResponse.cars));
    store.dispatch(setBlogsToHomePage(blogs));
  }

  return {
    chunks: ['home'],
    title: getTitle({ localize }),
    description: getDescription({ localize }),
    seo: {
      ogp: seoItemOgp({ localize }),
    },
    component: (
      <Layout>
        <Home />
      </Layout>
    ),
  };
}

export default action;
