import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cn from 'classnames';

import logo from './logo_color.svg';
import logoFull from './logo_color_full.svg';
import logoMono from './logo_mono.svg';
import logoMonoFull from './logo_mono_full.svg';
import logoText from './logotype.svg';

import s from './Logo.css';

const Logo = ({ mono, full, textOnly, className, ...props }) => {
  let current = logoText;

  if (!textOnly) {
    if (full) {
      current = mono ? logoMonoFull : logoFull;
    } else {
      current = mono ? logoMono : logo;
    }
  }

  return (
    <img
      className={cn(
        s.root,
        { [s.mono]: mono, [s.full]: full, [s.text]: textOnly },
        className,
      )}
      src={current}
      alt="Avtoprod.ua"
      {...props}
    />
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  full: PropTypes.bool,
  mono: PropTypes.bool,
  textOnly: PropTypes.bool,
};

Logo.defaultProps = {
  className: null,
  full: false,
  mono: false,
  textOnly: false,
};

export default withStyles(s)(Logo);
