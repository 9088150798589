import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Icon from 'react-icons-kit';
import { ic_menu as icMenu } from 'react-icons-kit/md/ic_menu';
import Link from 'components/Link';
import Logo from 'components/Logo';

import s from './HeaderLogo.css';

const HeaderLogo = ({ toggleMenu }) => (
  <div className={s.root}>
    <button
      className={`navbar-toggler ${s.toggleMenu}`}
      type="button"
      onClick={e => {
        e.preventDefault();
        toggleMenu();
      }}
    >
      <Icon icon={icMenu} size={25} />
    </button>
    <Link className={s.brand} to="/">
      <Logo full />
    </Link>
  </div>
);

HeaderLogo.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
};

export default withStyles(s)(HeaderLogo);
