import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';

import s from './GuideBanner.css';
import leaderBoard from './LeaderBoard.png';
// import mediumBanner from './MediumBanner.png';
import mediumBanner2 from './600.png';

const GuideBanner = ({ wide }) => (
  <div className={s.root}>
    <a
      href="https://guide.avtoprod.ua"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className={s.img}
        src={wide ? leaderBoard : mediumBanner2}
        alt="Тест-гайд який мій автомобіль"
      />
    </a>
  </div>
);

GuideBanner.propTypes = {
  wide: PropTypes.bool,
};

GuideBanner.defaultProps = {
  wide: false,
};
export default withStyles(s)(GuideBanner);
