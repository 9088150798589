import { connect } from 'react-redux';
import { resolveSearchQuery } from 'actions/search/searchBarActions';

import SearchBar from 'components/SearchBar';

function mapStateToProps({ searchBar }) {
  return { searchBar };
}

export default connect(mapStateToProps, { resolveSearchQuery })(SearchBar);
