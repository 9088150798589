import React from 'react';
import PropTypes from 'prop-types';
import slice from 'lodash/slice';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './SmallGallery.css';
import ThumborImage from '../../ThumborImage';

const SmallGallery = ({ images, limit, title }) => (
  <div className={s.root}>
    {slice(images, 0, limit).map((image, i) => (
      <ThumborImage
        key={image.url}
        className={s.img}
        width={280}
        src={image.url}
        alt={`${title} - фото №${i}`}
        background
      />
    ))}
  </div>
);

SmallGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  limit: PropTypes.number,
  title: PropTypes.string,
};

SmallGallery.defaultProps = {
  limit: 4,
  title: 'Оголошення',
};

export default withStyles(s)(SmallGallery);
