import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import BeatLoader from 'react-spinners/BeatLoader';
import { withLocalize } from 'locales';

import s from './LoadingOverlay.css';
import messages from './messages';

class LoadingOverlay extends React.Component {
  static propTypes = {
    loading: PropTypes.shape(),
    text: PropTypes.string,
    localize: PropTypes.func.isRequired,
  };

  static defaultProps = {
    text: null,
    loading: {
      pending: 0,
      done: true,
    },
  };

  render() {
    const { done } = this.props.loading;
    const active = !done;
    const { text, localize } = this.props;
    if (!active) {
      return null;
    }

    return (
      <div className={`${s.root}${active ? ' active' : ''}`}>
        <div className={s.overlay} />
        <div className={s.text}>
          <BeatLoader loading color="#fff'" />
          <p>{text || localize('Завантаження...')}</p>
        </div>
      </div>
    );
  }
}

export default withLocalize(messages)(withStyles(s)(LoadingOverlay));
