import React from 'react';

import Layout from 'components/Layout';
import RegionPage from 'containers/Seo/RegionPage';
import { carItemsRequest } from 'actions/carsActions';
import { setBreadcrumbs } from 'actions/app/breadcrumbs';
import { fetchMakes } from 'actions/catalog/makeActions';
import { setMakes, setSearchQuery } from 'actions/search/formActions';
import { makeLocalize } from 'locales';
import ogp from 'constants/ogp';
import { SITE_HOST } from 'constants/index';

import { getRegionUrl } from './urls';
import messages from './messages';

async function action({ store, fetch, region, path, intl }) {
  const localize = makeLocalize(intl, messages);
  const title = localize('Продаж легкових автомобілів {region}', {
    region: region.title,
  });
  const description = localize(
    'Продаж легкових авто {region}. Б/в та нові. Швидкий підбір авто в регіоні {region}. Актуальні ціни та фото.',
    {
      region: region.title,
    },
  );

  store.dispatch(
    setBreadcrumbs([
      { title: 'Avtoprod', href: '/' },
      { title: region.title, href: getRegionUrl(region) },
      { title, active: true },
    ]),
  );

  const query = {
    locations: region.id,
  };

  store.dispatch(setSearchQuery(query));

  const { cars } = await carItemsRequest(fetch, query);
  const makes = await fetchMakes(fetch);
  store.dispatch(setMakes(makes));

  const pageOgp = {
    ...ogp,
    title,
    description,
    url: `${SITE_HOST}${path}`,
  };

  return {
    chunks: ['seo'],
    title,
    description,
    seo: {
      ogp: pageOgp,
    },
    component: (
      <Layout>
        <RegionPage cars={cars} region={region} title={title} makes={makes} />
      </Layout>
    ),
  };
}

export default action;
