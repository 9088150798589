exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".x4l_F{width:100%;height:300px}", ""]);

// exports
exports.locals = {
	"chart": "x4l_F"
};