import { defineMessages } from 'react-intl';

export default defineMessages({
  'Інші марки на Avtoprod': {
    id: 'seo:makePage:otherMakes',
    defaultMessage: 'Інші марки на Avtoprod',
  },
  Моделі: {
    id: 'seo:makePage:models',
    defaultMessage: 'Моделі',
  },
  '{make} в інших регіонах України': {
    id: 'seo:makePage:otherRegions',
    defaultMessage: '{make} в інших регіонах України',
  },
});
