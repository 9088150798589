import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'reactstrap';
import cn from 'classnames';
import Icon from 'react-icons-kit';
import { checkSquareO } from 'react-icons-kit/fa/checkSquareO';
import { dollar } from 'react-icons-kit/fa/dollar';
import { newspaperO } from 'react-icons-kit/fa/newspaperO';
import { search } from 'react-icons-kit/fa/search';

import Link from '../Link';
import Panel from '../Helpers/Panel';
import { CarVerticalLink, CHANNELS } from '../CarVertical';

import s from './Recomendations.css';

const ICON_SIZE = 25;

const BLOG_SECTION = {
  title: 'Блог',
  icon: newspaperO,
  to: '/blog',
  links: [
    [
      '/blog/сервіс-пальне-автопрод__228.html',
      <>
        <FormattedMessage
          id="home.recommends.fuel_1"
          defaultMessage="Сервіс"
          description=""
        />{' '}
        <strong>
          <FormattedMessage
            id="home.recommends.fuel_2"
            defaultMessage="Пальне Автопрод"
            description=""
          />
        </strong>
      </>,
    ],
    [
      '/blog/топ-6-економічних-автомобілів__209.html',
      <FormattedMessage
        id="home.recommends.blog.1"
        defaultMessage="ТОП 6 економічних автомобілів"
        description=""
      />,
    ],
    [
      '/blog/найдешевші-електромобілі-на-американському-ринку__232.html',
      <FormattedMessage
        id="home.recommends.blog.2"
        defaultMessage="Найдешевші автомобілі з Америки"
        description=""
      />,
    ],
    [
      '/blog/номерні-знаки-автомобілів-в-україні-звідки-авто__210.html',
      <FormattedMessage
        id="home.recommends.blog.3"
        defaultMessage="Номерні знаки автомобілів в Україні"
        description=""
      />,
    ],
    [
      '/blog/як-ввезти-та-розмитнити-автомобіль-з-європи__208.html',
      <FormattedMessage
        id="home.recommends.blog.4"
        defaultMessage="Як ввезти та розмитнити автомобіль з Європи?"
        description=""
      />,
    ],
    [
      'https://t.me/avtoprod_ua',
      <FormattedMessage
        id="home.recommends.telegram.1"
        defaultMessage="Щоденне оновлення цін на пальне та авто новини в Telegram"
        description=""
      />,
    ],
  ],
};

const SEARCH_SECTION = {
  title: (
    <FormattedMessage
      id="home.recommends.search.title"
      defaultMessage="Знайти автомобіль"
      description=""
    />
  ),
  icon: search,
  to: '/cars',
  links: [
    // ['/cars?locations=24288', 'Знайти БВ авто у Києві'],
    [
      '/cars?mileage_t=100',
      <FormattedMessage
        id="home.recommends.search.1"
        defaultMessage="Знайти авто: пробіг до 100 тис. км"
        description=""
      />,
    ],
    [
      '/cars?price_t=10000',
      <FormattedMessage
        id="home.recommends.search.2"
        defaultMessage="Знайти авто: до 10000$"
        description=""
      />,
    ],
    [
      '/cars?fuel=38370',
      <FormattedMessage
        id="home.recommends.search.3"
        defaultMessage="Знайти електрокар"
        description=""
      />,
    ],
    [
      '/cars?body=37966',
      <FormattedMessage
        id="home.recommends.search.4"
        defaultMessage="Знайти позашляховик"
        description=""
      />,
    ],
    [
      '/cars?body=38004',
      <FormattedMessage
        id="home.recommends.search.5"
        defaultMessage="Знайти кросовер"
        description=""
      />,
    ],
    [
      'https://t.me/avtoprod_ua',
      <FormattedMessage
        id="home.recommends.search.telegram"
        defaultMessage="Нові оголошення в Telegram"
        description=""
      />,
    ],
  ],
};

const SELL_SECTION = {
  title: (
    <FormattedMessage
      id="home.recommends.sell.title"
      defaultMessage="Як продати авто?"
      description=""
    />
  ),
  icon: dollar,
  to: '/prodati/avto',
  links: [
    [
      '/prodati/avto',
      <FormattedMessage
        id="home.recommends.sell.1"
        defaultMessage="Додати оголошення"
        description=""
      />,
    ],
    [
      '/blog/як-треба-розмістити-оголошення-щоб-привернути-увагу-до-свого-автомобіля-як-продати-автомобіль-швидко__205.html',
      <FormattedMessage
        id="home.recommends.sell.2"
        defaultMessage="Як продати авто швидко?"
        description=""
      />,
    ],
    [
      '/order/6886',
      <FormattedMessage
        id="home.recommends.sell.3"
        defaultMessage="Які послуги допоможуть продати швидше?"
        description=""
      />,
    ],
    [
      '/dashboard/cars',
      <FormattedMessage
        id="home.recommends.sell.4"
        defaultMessage="Особистий кабінет"
        description=""
      />,
    ],
    [
      'https://www.instagram.com/avtoprod.ua/',
      <FormattedMessage
        id="home.recommends.sell.5"
        defaultMessage="Автопрод в Instagram"
        description=""
      />,
    ],
    [
      'https://t.me/avtoprod_ua',
      <FormattedMessage
        id="home.recommends.sell.teleram"
        defaultMessage="Автопрод в Telegram"
        description=""
      />,
    ],
  ],
};

const LinkComponent = ({ to, children, ...props }) =>
  to.startsWith('https://') ? (
    <a href={to} {...props} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <Link to={to} {...props}>
      {children}
    </Link>
  );

LinkComponent.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const LinkSection = ({ title, to, icon, links = [], children }) => (
  <Panel>
    <div className={s.title}>
      <Icon icon={icon} size={ICON_SIZE} className={s.icon} />
      <Link className={cn('lead', s.title)} to={to}>
        {title}
      </Link>
    </div>
    <hr />
    <ul className="list-unstyled">
      {children}
      {links.map(([link, label]) => (
        <li key={link} className="mb-1">
          <LinkComponent to={link}>{label}</LinkComponent>
        </li>
      ))}
    </ul>
  </Panel>
);

LinkSection.propTypes = {
  title: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.shape().isRequired,
  links: PropTypes.arrayOf(PropTypes.arrayOf).isRequired,
  children: PropTypes.node,
};

LinkSection.defaultProps = {
  children: null,
};

const Recommendations = () => (
  <div>
    {/* <h4 className="h4">Рекомендуємо</h4> */}
    <Row>
      <Col md={4} xs={12}>
        <LinkSection {...BLOG_SECTION} />
      </Col>
      <Col md={4} xs={12}>
        <LinkSection {...SEARCH_SECTION}>
          <li className="mb-1">
            <CarVerticalLink
              version="v3"
              channel={CHANNELS.HOME_RECOMMENDATION}
            >
              <Icon icon={checkSquareO} size={18} />{' '}
              <FormattedMessage
                id="home.recommends.carvertical"
                defaultMessage="Перевірка авто за VIN кодом"
                description=""
              />
            </CarVerticalLink>
          </li>
        </LinkSection>
      </Col>
      <Col md={4} xs={12}>
        <LinkSection {...SELL_SECTION} />
      </Col>
    </Row>
  </div>
);

export default withStyles(s)(Recommendations);
