import React from 'react';
// import PropTypes from 'prop-types';
import JsonLd from './JsonLd';

const WebSite = () => {
  const data = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: 'https://avtoprod.ua/',
    name: 'Avtoprod.ua',
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://avtoprod.ua/search?query={search_term_string}',
      'query-input': 'required name=search_term_string',
    },
  };
  return <JsonLd data={data} />;
};

export default WebSite;
