import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withLocalize } from 'locales';
import messages from 'components/AddCarForm/messages';

import s from './TechInfo.css';

const TechInfo = ({ car, localize }) => (
  <Row className={s.tech_breadcrumbs}>
    {Boolean(car.mileage) && (
      <Col className={s.tech_item} xs="3">
        <span className={s.info_label}>{localize('Пробіг')}</span>
        {`${car.mileage} ${localize('тис. км.')}`}
      </Col>
    )}
    {car.fuel && (
      <Col className={s.tech_item} xs="3">
        <span className={s.info_label}>{localize('Двигун')}</span>
        {car.fuel.title}
        {car.engine_capacity
          ? `, ${car.engine_capacity} ${localize('л.')}`
          : null}
      </Col>
    )}
    {car.gears_type && (
      <Col className={s.tech_item} xs="3">
        <span className={s.info_label}>{localize('КПП')}</span>
        {car.gears_type.title}
      </Col>
    )}
    {car.body && (
      <Col className={s.tech_item} xs="3">
        <span className={s.info_label}>{localize('Кузов')}</span>
        {car.body.title}
      </Col>
    )}
  </Row>
);

TechInfo.propTypes = {
  car: PropTypes.shape().isRequired,
  localize: PropTypes.func.isRequired,
};

export default withLocalize(messages)(withStyles(s)(TechInfo));
