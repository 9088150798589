exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1IJHi{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-pack:justify;justify-content:space-between}._1Ftra{margin:5px;width:160px!important;height:110px!important;border:1px solid #e9ecef!important}@media screen and (max-width:415px){._1Ftra{width:180px!important;height:130px!important}}@media screen and (max-width:391px){._1Ftra{width:170px!important;height:130px!important}}@media screen and (max-width:376px){._1Ftra{width:160px!important;height:120px!important}}@media screen and (max-width:361px){._1Ftra{width:155px!important;height:115px!important}}@media screen and (max-width:321px){._1Ftra{width:135px!important;height:100px!important}}@media screen and (min-width:1200px){._1Ftra{width:190px!important;height:120px!important}}", ""]);

// exports
exports.locals = {
	"root": "_1IJHi",
	"img": "_1Ftra"
};