import { connect } from 'react-redux';

import { toggleMenu } from '../actions/app/menu';
import Navigation from '../components/Navigation';

function mapStateToProps({ menu }) {
  return { menu };
}

export default connect(mapStateToProps, { toggleMenu })(Navigation);
