import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Icon from 'react-icons-kit';
import { info } from 'react-icons-kit/fa/info';
import { exclamation } from 'react-icons-kit/fa/exclamation';

import s from './Statuses.css';

class Statuses extends Component {
  static propTypes = {
    car: PropTypes.shape().isRequired,
  };

  render() {
    const { car } = this.props;
    return (
      <div>
        {car.customs && (
          <div className={`${s.info_badge} text-danger`}>
            <Icon icon={info} /> {car.customs.title}
          </div>
        )}
        {car.kredit && (
          <div className={`${s.info_badge} text-info`}>
            <Icon icon={info} /> {car.kredit.title}
          </div>
        )}
        {car.other && (
          <div className={`${s.info_badge} text-warning`}>
            <Icon icon={exclamation} /> {car.other.title}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(s)(Statuses);
