import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col, Card, CardBody } from 'reactstrap';

import AdSenseBanner from 'containers/AdSenseBanner';
import SearchForm from 'containers/Seo/RegionSearchForm';
import { withLocalize } from 'locales';

import Links from '../../Catalog/Links';
import { getRegionUrl, getMakeUrl } from '../../../routes/seo/urls';
import AllMakes from '../AllMakes';
import CarsList from '../CarsList';
import Pagination from '../Pagination';
// import { WrappedGift } from '../../Emoji';

import s from './RegionPage.css';
import messages from './messages';

const RegionPage = props => {
  const { cars, region, locations, makes, title, localize } = props;
  // const isEasterEgg = region && region.id === '9062';

  return (
    <Row className={s.root}>
      <Col md="8">
        <Row>
          <Col md="12">
            <h1>{title}</h1>
            <SearchForm />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <CarsList cars={cars} />
            <Pagination {...props} />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <AllMakes
              makes={makes}
              title={
                <>
                  {localize('{region}. Інші марки авто.', {
                    region: region.title,
                  })}{' '}
                  {/* {isEasterEgg && <WrappedGift />} */}
                </>
              }
              linkUrl={item => getMakeUrl(item, region)}
            />
          </Col>
        </Row>
      </Col>
      <Col md="4">
        <Row>
          <Col md="12" className="mb-3">
            <AdSenseBanner slot="8181435095" />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className={s.links}>
              <CardBody>
                <p className="lead">{localize('Інші регіони')}</p>
                <hr className="my-2" />
                <Links
                  catalogItems={locations}
                  link={item => getRegionUrl(item)}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

RegionPage.propTypes = {
  title: PropTypes.string.isRequired,
  cars: PropTypes.shape().isRequired,
  region: PropTypes.shape().isRequired,
  makes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  localize: PropTypes.func.isRequired,
};

export default withStyles(s)(withLocalize(messages)(RegionPage));
