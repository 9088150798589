exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AZpQf{margin-bottom:10px;position:relative}._1lSWn{width:100%}", ""]);

// exports
exports.locals = {
	"root": "AZpQf",
	"img": "_1lSWn"
};