exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Uvzj{font-size:12.8px;font-size:.8rem;text-align:center}", ""]);

// exports
exports.locals = {
	"root": "_2Uvzj"
};