exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Roboto\",sans-serif;--link-color:#333;--link-color-ligther:rgba(51,51,51,.7);--link-hover-color:rgba(27,199,68,.9);--ap-orange-color:#ffaa4d;--text-white-stroke-shadow:-1px -1px 0 #fff,1px -1px 0 #fff,-1px 1px 0 #fff,1px 1px 0 #fff;--text-black-stroke-shadow:-1px -1px 0 #333,1px -1px 0 #333,-1px 1px 0 #333,1px 1px 0 #333;--max-content-width:1400px;--backgroud-body:#f9f9f9;--border-main-style:1px solid #eee;--main-box-shadow:0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.08);--item-box-shadow:0 0 4px 1px rgba(0,0,0,.08);--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--list-item-label-color:#1bc744;--price-background-color:#1bc744;--service-box-shadow:0 0 3.2px 3.2px rgba(51,51,51,.2);--service-box-shadow:0 0 0.2rem 0.2rem rgba(51,51,51,.2)}._1UUMy{background:hsla(0,0%,80%,.6);padding:20px 15px;color:rgba(51,51,51,.7)}._1UUMy a,._1UUMy a:active,._1UUMy a:visited{color:rgba(51,51,51,.9);text-decoration:none}._1UUMy a:hover{color:rgba(51,51,51,.4)}._1UUMy ul{list-style:none;padding:0}._22Qhe{display:-ms-flexbox;display:flex}._34Amj{height:60px;margin-left:-15px}._2FvMY{font-size:17.6px;font-size:1.1rem}._3sd-L{font-size:16px;font-size:1rem;display:block}._2fG-x{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}._2Kd8r{width:48px}._18B7f{color:#1a1f71}._2hpKQ{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between}@media (max-width:576px){._2hpKQ{-ms-flex-direction:column;flex-direction:column;margin-bottom:10px}}", ""]);

// exports
exports.locals = {
	"root": "_1UUMy",
	"footer_logo": "_22Qhe",
	"logo": "_34Amj",
	"help_email": "_2FvMY",
	"phones": "_3sd-L",
	"socials": "_2fG-x",
	"mc_logo": "_2Kd8r",
	"visa_logo": "_18B7f",
	"since": "_2hpKQ"
};