import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Icon from 'react-icons-kit';
import { chevronUp } from 'react-icons-kit/fa/chevronUp';
import { chevronDown } from 'react-icons-kit/fa/chevronDown';

import Link from '../../../Link';
import s from './LinkItem.css';

const Chevron = props => {
  const { visible, size, onClick } = props;
  return (
    <Icon
      icon={visible ? chevronUp : chevronDown}
      size={size}
      className={s.chevron}
      onClick={onClick}
    />
  );
};

Chevron.propTypes = {
  visible: PropTypes.bool.isRequired,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

Chevron.defaultProps = {
  size: 12,
  onClick: () => undefined,
};

class LinkItem extends React.Component {
  static propTypes = {
    item: PropTypes.shape().isRequired,
    link: PropTypes.func.isRequired,
    title: PropTypes.func,
    icon: PropTypes.func,
  };

  static defaultProps = {
    title: item => `${item.title}`,
    icon: null,
  };

  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  render() {
    const { item, link, title, icon } = this.props;
    return (
      <li key={item.id} className={s.link}>
        <span className={s.linkTitle}>
          {icon && icon(item)}
          <Link to={link(item)}>{title(item)}</Link>
          {item.childrens && (
            <Chevron
              visible={this.state.visible}
              onClick={() => this.setState({ visible: !this.state.visible })}
            />
          )}
        </span>
        {item.childrens && (
          <ul
            className={`list-unstyled ${s.subgroup} ${
              !this.state.visible ? s.hide : ''
            }`}
            key={`group${item.id}`}
          >
            {item.childrens.map(i => {
              const to = link(i);
              const itemTitle = title(i);
              return (
                <li key={i.id} className={s.link}>
                  <Link to={to}>{itemTitle}</Link>
                </li>
              );
            })}
          </ul>
        )}
      </li>
    );
  }
}

export default withStyles(s)(LinkItem);
