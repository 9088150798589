import React from 'react';

import Layout from 'components/Layout';
import ModelPage from 'containers/Seo/ModelPage';
import { carItemsRequest } from 'actions/carsActions';
import { setBreadcrumbs } from 'actions/app/breadcrumbs';
import { fetchModels } from 'actions/catalog/modelActions';
import { setModels, setSearchQuery } from 'actions/search/formActions';

import ogp from 'constants/ogp';
import { SITE_HOST } from 'constants/index';
import { makeLocalize } from 'locales';

import { getRegionUrl, getMakeUrl, getModelUrl } from './urls';
import messages from './messages';

async function action({ store, fetch, make, model, region, path, intl }) {
  const localize = makeLocalize(intl, messages);
  const regionTitle = region ? ` ${region.title}` : '';
  const context = {
    make: make.title,
    model: model.title,
    region: regionTitle,
  };
  const title = localize(
    'Продаж легкових автомобілів {make} {model}{region}.',
    context,
  );
  const description = localize(
    'Продаж легкових авто {make} {model} б/в та нові{region}. Швидкий підбір авто. Актуальні ціни та фото. Технічні характеристики автомобілів {make} {model}.',
    context,
  );

  store.dispatch(
    setBreadcrumbs([
      { title: 'Avtoprod', href: '/' },
      ...(region ? [{ title: region.title, href: getRegionUrl(region) }] : []),
      { title: make.title, href: getMakeUrl(make) },
      { title: model.title, href: getModelUrl(make, model) },
      { title, active: true },
    ]),
  );

  const query = {
    make: make.id,
    model: model.id,
    ...(region ? { locations: region.id } : null),
  };

  const models = await fetchModels(fetch, make.id);
  store.dispatch(setModels(models));
  store.dispatch(setSearchQuery(query));

  const { cars } = await carItemsRequest(fetch, query);

  const pageOgp = {
    ...ogp,
    title,
    description,
    url: `${SITE_HOST}${path}`,
  };

  return {
    chunks: ['seo'],
    title,
    description,
    seo: {
      ogp: pageOgp,
    },
    component: (
      <Layout>
        <ModelPage
          cars={cars}
          region={region}
          make={make}
          model={model}
          title={title}
        />
      </Layout>
    ),
  };
}

export default action;
