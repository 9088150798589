import React from 'react';
import PropTypes from 'prop-types';

import Pagination from 'components/Pagination';

const SeoPagination = props => {
  const { cars, make, region, model } = props;

  const searchQuery = {
    ...(make && make.id ? { make: make.id } : null),
    ...(model && model.id ? { model: model.id } : null),
    ...(region && region.id ? { locations: region.id } : null),
  };

  return <Pagination path="/cars" count={cars.count} query={searchQuery} />;
};

SeoPagination.propTypes = {
  cars: PropTypes.shape().isRequired,
  make: PropTypes.shape(),
  model: PropTypes.shape(),
  region: PropTypes.shape(),
};

SeoPagination.defaultProps = {
  make: null,
  model: null,
  region: null,
};

export default SeoPagination;
