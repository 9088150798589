import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import Link from '../Link';

import s from './Breadcrumbs.css';

class Breadcrumbs extends Component {
  static propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  renderBreadcrumbs = () => {
    const { breadcrumbs } = this.props;
    return breadcrumbs.map(
      ({ href, title, active }) =>
        active ? (
          <BreadcrumbItem active key={title}>
            {title}
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem key={title}>
            <Link to={href}>{title}</Link>
          </BreadcrumbItem>
        ),
    );
  };

  render() {
    if (this.props.breadcrumbs.length < 1) {
      return null;
    }

    return (
      <div className={`d-none d-md-block ${s.root}`}>
        <Breadcrumb className={s.breadcrumb}>
          {this.renderBreadcrumbs()}
        </Breadcrumb>
      </div>
    );
  }
}

export default withStyles(s)(Breadcrumbs);
