exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._22-PG{background:#eee;font-weight:600}._108Bz{padding-left:20px;font-size:12.8px;font-size:.8rem}", ""]);

// exports
exports.locals = {
	"region": "_22-PG",
	"city": "_108Bz"
};