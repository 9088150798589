import { connect } from 'react-redux';
import omit from 'lodash/omit';

import { fetchModels, setModels } from '../../actions/search/formActions';
import SearchForm from '../../components/SearchForm';

function mapStateToProps({ search_form: searchForm }) {
  const initialValues = omit(searchForm.searchQuery, ['used']);
  return { search_form: searchForm, initialValues };
}

export default connect(mapStateToProps, { fetchModels, setModels })(SearchForm);
