export const AFFILIATE_ID = 'avtoprod';

export const LANDING_PAGES = {
  default: 'https://www.carvertical.com/ua',
  v3: 'https://www.carvertical.com/ua/landing/v3',
};

export const BANNER_IDS = {
  default: 'bfe4bac7',
  v3: 'f1781078',
};

export const CHANNELS = {
  HOME_PAGE: 'home',
  HOME_RECOMMENDATION: 'home_recommendation',
  BLOG_POST: 'blog',
  OFFER_PAGE: 'offer',
  PERSONAL_DASHBOARD: 'personal',
  NAVIGATION: 'nav',
  FOOTER: 'footer',
  SEARCH_TOP: 'search_top',
};
