import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import Icon from 'react-icons-kit';
import { user } from 'react-icons-kit/fa/user';
import Panel from 'components/Helpers/Panel';
import Link from 'components/Link';

import s from './AccountInfo.css';

const AccountInfo = props => {
  const { profile: { username } } = props;
  if (!username) {
    return null;
  }

  return (
    <Panel className={s.root}>
      <div>
        <Icon icon={user} size={32} />
      </div>
      <div>
        <FormattedMessage
          id="nav.accountinfo.heading"
          defaultMessage="Ви зайшли як:"
        />
      </div>
      <p>{username}</p>
      <Link to="/logout" className="btn btn-sm btn-outline-dark">
        <FormattedMessage id="nav.accountinfo.exit" defaultMessage="Вийти" />
      </Link>
    </Panel>
  );
};

AccountInfo.propTypes = {
  profile: PropTypes.shape().isRequired,
};

export default withStyles(s)(AccountInfo);
