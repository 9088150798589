import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import URI from 'urijs';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import omit from 'lodash/omit';

import { makeLocaleUrl, withLocalize } from '../../locales';
import history from '../../history';
import s from './SearchForm.css';
import LocationsSelect from '../Form/LocationsSelect';
import { renderField, renderSelect } from './renderers';
import { years } from './tools';
import validate from './validate';
import messages from './messages';

const renderOptions = options =>
  options.map(item => ({
    value: item.id,
    label: item.title,
  }));

class SearchForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    fetchModels: PropTypes.func.isRequired,
    setModels: PropTypes.func.isRequired,
    search_form: PropTypes.shape().isRequired,
    localize: PropTypes.func.isRequired,
  };

  static contextTypes = {
    fetch: PropTypes.func.isRequired,
    intl: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      detailView: false,
      yearsFrom: years(),
      yearsTo: years(),
    };
  }

  // componentDidMount() {
  //   if (this.props.search_form.searchQuery.make) {
  //     this.props.fetchModels(
  //       this.context.fetch,
  //       this.props.search_form.searchQuery.make,
  //     );
  //   }
  // }

  // onUsedChange = (used) => {
  //   this.onSubmit({ ...this.props.search_form.searchQuery, used });
  // }

  onMakeSelect = (event, newValue, previousValue) => {
    if (newValue && newValue !== previousValue) {
      this.props.fetchModels(this.context.fetch, newValue);
    }
    if (!newValue) {
      this.props.setModels([]);
    }
  };

  onYearFromChange = (event, value, previousValue) => {
    if (value !== previousValue) {
      this.setState({ yearsTo: years(value) });
    }
  };

  onSubmit = values => {
    const {
      intl: { locale },
    } = this.context;
    history.push(
      makeLocaleUrl(
        new URI('/cars').search(omit(values, ['page'])).toString(),
        locale,
      ),
    );
  };

  render() {
    const {
      handleSubmit,
      localize,
      search_form: { makes, models, body, fuel, gearsType },
    } = this.props;
    // const { used } = this.props.search_form.searchQuery;
    return (
      <div className={s.root}>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="form-row">
            <div className="col-md-4 col-sm-12">
              <Field
                name="locations"
                emptyLabel={localize('Регіон')}
                options={this.props.search_form.locations}
                component={LocationsSelect}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <Field
                name="make"
                emptyLabel={localize('Марка')}
                options={renderOptions(makes)}
                component={renderSelect}
                onChange={this.onMakeSelect}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <Field
                name="model"
                emptyLabel={localize('Модель')}
                options={renderOptions(models)}
                component={renderSelect}
                noResultsText={localize('спочатку оберіть марку')}
              />
            </div>
          </div>
          <div className={!this.state.detailView ? s.hide : ''}>
            <div className="form-row">
              <div className="col-md-4">
                <div className="form-row">
                  <div className="col-md-6">
                    <Field
                      name="year_f"
                      emptyLabel={localize('Рік від')}
                      options={this.state.yearsFrom.map(year => ({
                        value: `${year}`,
                        label: `${year}`,
                      }))}
                      component={renderSelect}
                      onChange={this.onYearFromChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      name="year_t"
                      emptyLabel={localize('до')}
                      options={this.state.yearsTo.map(year => ({
                        value: `${year}`,
                        label: `${year}`,
                      }))}
                      component={renderSelect}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-row">
                  <div className="col-md-6">
                    <Field
                      name="price_f"
                      placeholder={localize('Ціна $ від')}
                      component={renderField}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      name="price_t"
                      placeholder={localize('до')}
                      component={renderField}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-row">
                  <div className="col-md-6">
                    <Field
                      name="mileage_f"
                      placeholder={localize('Пробіг від')}
                      component={renderField}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      name="mileage_t"
                      placeholder={localize('до')}
                      component={renderField}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-4 col-sm-12">
                <Field
                  name="body"
                  emptyLabel={localize('Кузов')}
                  options={renderOptions(body)}
                  component={renderSelect}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <Field
                  name="fuel"
                  emptyLabel={localize('Двигун')}
                  options={renderOptions(fuel)}
                  component={renderSelect}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <Field
                  name="gears_type"
                  emptyLabel={localize('КПП')}
                  options={renderOptions(gearsType)}
                  component={renderSelect}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            {/* <div className="col text-left">
              <div className="btn-group">
                <button
                  type="button"
                  className={`btn ${ (used === undefined) ? 'btn-primary' : 'btn-secondary' }`}
                  onClick={()=> this.onUsedChange(undefined)}>Усі</button>
                <button
                  type="button" className={`btn ${ (used === 'true') ? 'btn-primary' : 'btn-secondary' }`}
                  onClick={()=> this.onUsedChange(true)}>Б/В</button>
                <button
                  type="button" className={`btn btn ${ (used === 'false') ? 'btn-primary' : 'btn-secondary' }`}
                  onClick={()=> this.onUsedChange(false)}>Нові</button>
              </div>
            </div> */}
            <div className="col text-right">
              <button
                onClick={() =>
                  this.setState({ detailView: !this.state.detailView })
                }
                type="button"
                className="btn btn-link"
              >
                {this.state.detailView ? localize('менше') : localize('більше')}{' '}
                {localize('фільтрів')}
              </button>
              <button className="btn btn-primary" type="submit">
                {localize('Пошук')}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export const factory = formName =>
  withLocalize(messages)(
    withStyles(s)(
      reduxForm({
        form: formName,
        validate,
      })(SearchForm),
    ),
  );

export default withLocalize(messages)(
  withStyles(s)(
    reduxForm({
      form: 'searchForm',
      validate,
    })(SearchForm),
  ),
);
