import React from 'react';
import PropTypes from 'prop-types';
import { loadSdk } from './utils';
import { AFFILIATE_ID, BANNER_IDS } from './config';

const CarVerticalBanner = ({
  variant,
  version,
  channel,
  width,
  height,
  responsive = false,
}) => {
  const [sdkLoaded, setSdkLoaded] = React.useState(false);

  React.useEffect(() => {
    // load sdk
    loadSdk(() => {
      setSdkLoaded(true);
    });
  }, []);

  React.useEffect(
    () => {
      if (sdkLoaded) {
        window.CVAff.load();
      }
    },
    [sdkLoaded, version],
  );

  if (!sdkLoaded) {
    return null;
  }

  const props = {
    'data-locale': 'uk',
    'data-a': AFFILIATE_ID,
    'data-b': BANNER_IDS[version],
    'data-chan': channel,
    'data-variant': `variant-${variant}`,
    'data-body-type': 'car',
    'ata-integration-type': 'banner',
    ...(responsive ? { style: { width: '100%', height: '100%' } } : null),
  };

  return <div data-cvaff style={{ width, height }} {...props} />;
};

CarVerticalBanner.propTypes = {
  version: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  variant: PropTypes.number,
  responsive: PropTypes.bool,
};

CarVerticalBanner.defaultProps = {
  width: '336px',
  height: '280px',
  variant: 2,
  responsive: false,
};

export default CarVerticalBanner;
