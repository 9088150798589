import reduce from 'lodash/reduce';
import find from 'lodash/find';

const SERVICES_MAP = {
  searchFirst: ({ slug }) => slug === 'search_first',
  offerOfDay: ({ slug }) => slug === 'offer_of_day',
  extendedFormat: ({ slug }) => slug === 'extended_format',
  listMorePhoto: ({ slug }) => slug === 'list_more_photo',
  searchIndependent: ({ slug }) => slug === 'search_independent',
  onMain: ({ slug }) => slug === 'on_main',
  inSearchList: ({ slug }) => slug === 'in_search_list',
  labelBestPrice: ({ slug }) => slug === 'label_best_price',
  labelSuperPrice: ({ slug }) => slug === 'label_super_price',
  labelWithDiscount: ({ slug }) => slug === 'label_with_discount',
  labelSuperProposal: ({ slug }) => slug === 'label_super_proposal',
  labelTopProposal: ({ slug }) => slug === 'label_top_proposal',
};

export const parseServices = (services = []) => {
  const properties = reduce(
    services,
    (props, item) => [...props, ...(item.properties || [])],
    [],
  );
  return reduce(
    SERVICES_MAP,
    (result, validation, key) => ({
      ...result,
      [key]: Boolean(find(properties, validation)),
    }),
    {},
  );
};

export default undefined;
