export const loadSdk = (callback = () => null) => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (window.carVerticalSnippetAdded) {
    callback();
    return null;
  }

  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://aff.carvertical.com/sdk.js';
  script.onload = () => {
    window.carVerticalSnippetAdded = true;
    callback();
  };
  head.appendChild(script);
  return null;
};

export default undefined;
