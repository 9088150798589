export const ALL_MAKES = [
  'abarth',
  'ac',
  'acura',
  'alfa-romeo',
  'alpina',
  'alpine',
  'aro',
  'artega',
  'asia',
  'aston-martin',
  'astro',
  'audi',
  'austin',
  'autobianchi',
  'baltijas-dzips',
  'bee-bee',
  'beijing',
  'bentley',
  'bertone',
  'bitter',
  'blonell',
  'bmw',
  'brilliance',
  'bristol',
  'bufori',
  'bugatti',
  'buick',
  'byd',
  'cadillac',
  'callaway',
  'carbodies',
  'caterham',
  'changan',
  'changfeng',
  'chery',
  'chevrolet',
  'chrysler',
  'citroen',
  'cizeta',
  'coggiola',
  'dacia',
  'dadi',
  'daewoo',
  'daf',
  'daihatsu',
  'daimler',
  'dallas',
  'de-lorean',
  'derways',
  'de-tomaso',
  'dodge',
  'dongfeng',
  'doninvest',
  'donkervoort',
  'ds',
  'eagle',
  'faw',
  'ferrari',
  'fiat',
  'ford',
  'fso',
  'fuqi',
  'gaz',
  'geely',
  'genesis',
  'geo',
  'gmc',
  'gonow',
  'great-wall',
  'hafei',
  'hindustan',
  'holden',
  'honda',
  'huanghai',
  'hummer',
  'hurtan',
  'hyundai',
  'infiniti',
  'innocenti',
  'invicta',
  'iran-khodro',
  'irmscher',
  'isdera',
  'isuzu',
  'iveco',
  'izh',
  'jac',
  'jaguar',
  'jeep',
  'jensen',
  'jiangling',
  'kamaz',
  'kia',
  'koenigsegg',
  'ktm',
  'lamborghini',
  'lancia',
  'land-rover',
  'landwind',
  'lexus',
  'liebao-motor',
  'lifan',
  'lincoln',
  'lotus',
  'lti',
  'luaz',
  'mahindra',
  'marcos',
  'marlin',
  'maruti',
  'maserati',
  'maybach',
  'mazda',
  'mcc',
  'mclaren',
  'mega',
  'mercedes-benz',
  'mercury',
  'metrocab',
  'mg',
  'microcar',
  'minelli',
  'mini',
  'mitsubishi',
  'mitsuoka',
  'monte-carlo',
  'morgan',
  'morris',
  'moskvich',
  'nissan',
  'noble',
  'oldsmobile',
  'opel',
  'osca',
  'pagani',
  'panoz',
  'paykan',
  'perodua',
  'peugeot',
  'plymouth',
  'pontiac',
  'porsche',
  'premier',
  'proton',
  'puch',
  'puma',
  'qvale',
  'reliant',
  'renault',
  'renault-samsung',
  'rolls-royce',
  'ronart',
  'rover',
  'saab',
  'saleen',
  'saturn',
  'scion',
  'seat',
  'seaz',
  'shuanghuan',
  'sin-cars',
  'skoda',
  'sma',
  'smart',
  'smz',
  'soueast',
  'spectre',
  'spyker',
  'ssangyong',
  'subaru',
  'suzuki',
  'tagaz',
  'talbot',
  'tata',
  'tatra',
  'tesla',
  'tianma',
  'tianye',
  'tofas',
  'tonggong',
  'toyota',
  'trabant',
  'triumph',
  'tvr',
  'uaz',
  'vauxhall',
  'vaz',
  'vector',
  'venturi',
  'vespa',
  'volkswagen',
  'volvo',
  'vw-porsche',
  'wartburg',
  'westfield',
  'wiesmann',
  'xin-kai',
  'yuejin',
  'zastava',
  'zaz',
  'zenvo',
  'zil',
  'zx',
];

export const POPULAR_MAKES = [
  'acura',
  'alfa-romeo',
  'alpina',
  'aston-martin',
  'audi',
  'bentley',
  'bmw',
  'bugatti',
  'buick',
  'byd',
  'cadillac',
  'chery',
  'chevrolet',
  'chrysler',
  'citroen',
  'dacia',
  'daewoo',
  'daf',
  'daihatsu',
  'dodge',
  'ferrari',
  'fiat',
  'ford',
  'gaz',
  'geely',
  'genesis',
  'gmc',
  'great-wall',
  'honda',
  'hummer',
  'hyundai',
  'infiniti',
  'isuzu',
  'iveco',
  'jac',
  'jaguar',
  'jeep',
  'kia',
  'lancia',
  'land-rover',
  'lexus',
  'lifan',
  'lincoln',
  'lotus',
  'maserati',
  'maybach',
  'mazda',
  'mercedes-benz',
  'mg',
  'mini',
  'mitsubishi',
  'nissan',
  'opel',
  'peugeot',
  'porsche',
  'renault',
  'rolls-royce',
  'seat',
  'skoda',
  'smart',
  'ssangyong',
  'subaru',
  'suzuki',
  'tesla',
  'toyota',
  'vaz',
  'volkswagen',
  'volvo',
  'zaz',
];

export default undefined;
