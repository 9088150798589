import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';
import cn from 'classnames';
import uaFlag from './ukraine-flag-waving-icon-64.png';
import s from './HelpZsu.css';

const LINK =
  'https://bank.gov.ua/ua/news/all/natsionalniy-bank-vidkriv-spetsrahunok-dlya-zboru-koshtiv-na-potrebi-armiyi';

const HelpZsu = ({ className, children }) => (
  <a
    className={cn(className, s.zsu)}
    target="_blank"
    rel="noopener noreferrer"
    href={LINK}
  >
    <img src={uaFlag} className={s.flag} alt="Stand with Ukraine" />
    <span className={s.text}>{children}</span>
  </a>
);

HelpZsu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

HelpZsu.defaultProps = {
  className: '',
  children: 'Допомога ЗСУ!',
};

export default withStyles(s)(HelpZsu);
