import PropTypes from 'prop-types';
import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ResponsiveLine } from '@nivo/line';

import LoadingBar from 'components/LoadingBar';

import s from './Statistics.css';

const commonProperties = {
  margin: { top: 20, right: 20, bottom: 60, left: 40 },
  animate: false,
  enableSlices: 'x',
};

const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
  <g>
    <circle
      fill="#fff"
      r={size / 2}
      strokeWidth={borderWidth}
      stroke={borderColor}
    />
    <circle
      r={size / 5}
      strokeWidth={borderWidth}
      stroke={borderColor}
      fill={color}
      fillOpacity={0.35}
    />
  </g>
);

const Chart = ({ data }) => {
  if (data === null) {
    return <LoadingBar />;
  }

  if (data && data.length === 0) {
    return (
      <p className="lead text-center">
        На даний момент статистика по оголошенню не доступна
      </p>
    );
  }

  const metricMap = {
    car_offer_views_count: 'Кількість переглядів',
    car_offer_shows_count: 'Кількість показів',
  };

  const dataMap = data.reduce(
    (res, item) => ({
      ...res,
      [item.metric]: [
        ...(res[item.metric] || []),
        { x: item.date, y: item.value },
      ],
    }),
    {},
  );

  const finalData = Object.entries(metricMap).map(([slug, title]) => ({
    id: title,
    data: dataMap[slug],
  }));

  return (
    <div className={s.chart}>
      <ResponsiveLine
        {...commonProperties}
        data={finalData}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          useUTC: false,
          precision: 'day',
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
          type: 'linear',
          stacked: false,
        }}
        axisLeft={{
          legend: '',
          legendOffset: 12,
        }}
        axisBottom={{
          format: '%b %d',
          tickValues: 'every day',
          legend: '',
          legendOffset: 32,
        }}
        curve="linear"
        enablePointLabel
        pointSymbol={CustomSymbol}
        pointSize={16}
        pointBorderWidth={1}
        pointBorderColor={{
          from: 'color',
          modifiers: [['darker', 0.3]],
        }}
        useMesh
      />
    </div>
  );
};

const Statistics = ({ car, setVisible, fetch, auth, show = false }) => {
  const toggle = () => setVisible(!show);
  const [stats, setData] = React.useState(null);
  const isPayed =
    (car.services && car.services.length > 0) || auth.userId === 1;

  React.useEffect(
    () => {
      if (show && isPayed) {
        fetch('/graphql', {
          body: JSON.stringify({
            query: `{
              carStatistics(id: ${car.id}) {
                date
                metric
                value
            }}`,
          }),
        })
          .then(response => response.json())
          .then(data => setData(data.data.carStatistics));
      }
      return () => {
        setData(null);
      };
    },
    [isPayed, car.id, show],
  );

  return (
    <Modal isOpen={show} toggle={toggle} className={s.root}>
      <ModalHeader toggle={toggle}>
        Статистика оголошення {car.title}
      </ModalHeader>
      <ModalBody>
        {isPayed && stats ? (
          <Chart car={car} data={stats} />
        ) : (
          <p className="lead text-center">
            Cтатистика доступна для оголошень, що рекламуються
          </p>
        )}
      </ModalBody>
    </Modal>
  );
};

Statistics.propTypes = {
  car: PropTypes.shape().isRequired,
  setVisible: PropTypes.func.isRequired,
  show: PropTypes.bool,
  auth: PropTypes.shape(),
  fetch: PropTypes.func.isRequired,
};

Statistics.defaultProps = {
  show: false,
  auth: {},
};

export default withStyles(s)(Statistics);
