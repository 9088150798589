import React from 'react';
import { Badge } from 'reactstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Icon from 'react-icons-kit';
import { gift } from 'react-icons-kit/fa/gift';
import { FormattedMessage } from 'react-intl';

import s from './WithDiscountLabel.css';

const WithDiscountLabel = () => (
  <div className={s.root}>
    <Badge className={s.label} color="warning">
      <Icon icon={gift} />
      <span className={s.text}>
        <FormattedMessage
          id="withdiscount.label"
          defaultMessage="продаж зі знижкою"
          description=""
        />
      </span>
    </Badge>
  </div>
);

export default withStyles(s)(WithDiscountLabel);
