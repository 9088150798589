import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import BarLoader from 'react-spinners/BarLoader';
import { withLocalize } from 'locales';

import s from './LoadingBar.css';
import messages from './messages';

class LoadingBar extends React.Component {
  static propTypes = {
    text: PropTypes.string,
    localize: PropTypes.func.isRequired,
  };

  static defaultProps = {
    text: null,
  };

  render() {
    const { localize, text } = this.props;
    return (
      <div className={s.root}>
        <div className={s.text}>
          <BarLoader loading color="#333" width={300} height={10} />
          <p>{text || localize('Завантаження...')}</p>
        </div>
      </div>
    );
  }
}

export default withLocalize(messages)(withStyles(s)(LoadingBar));
