import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import SearchForm from 'containers/Home/SearchForm';
import PayedCars from 'containers/Home/PayedCars';
import BlogWidget from 'containers/Home/BlogWidget';
import Makes from 'containers/Home/Makes';
import Regions from 'containers/Home/Regions';
import AdSenseBanner from 'containers/AdSenseBanner';

import Panel from 'components/Helpers/Panel';
import HelpZsu from 'components/Helpers/HelpZsu';
// import Link from 'components/Link';
import SeoDescriptionText from './SeoDescriptionText';
import Recommendations from './Recomendations';

import s from './Home.css';
import GuideBanner from '../GuideBanner';

const Home = () => (
  <div className={s.root}>
    <Row>
      <Col className="mb-3">
        <HelpZsu>Разом до перемоги! Допомога Збройним Силам України!</HelpZsu>
      </Col>
    </Row>
    <Row>
      <Col md="8" xs="12" className="mb-3">
        <Row>
          <Col xs="12">
            <Panel>
              <h1 className="h4">
                <FormattedMessage
                  id="home.heading.main"
                  defaultMessage="Найсвіжіші оголошення про продаж авто ТУТ!"
                  description=""
                />
              </h1>
              <hr className="my-3" />
              <SearchForm />
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <PayedCars />
          </Col>
        </Row>
      </Col>
      <Col xs="12" md="4">
        {/* <Panel className="mb-3"> */}
        {/*  <p className="h5 text-center">Розміщення БЕЗКОШТОВНЕ</p> */}
        {/*  <hr className="my-3" /> */}
        {/*  <p className="text-center"> */}
        {/*    <Link className="btn btn-success" to="/add"> */}
        {/*      Розмістити БЕЗКОШТОВНО */}
        {/*    </Link> */}
        {/*  </p> */}
        {/* </Panel> */}
        <Row>
          <Col xs="12" className="mb-3">
            <GuideBanner />
            <AdSenseBanner slot="8181435095" />
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col md="6" xs="12">
        <BlogWidget />
      </Col>
      <Col md="6" xs="12">
        <Row>
          <Col className="mb-3">
            <Regions />
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col className="mb-3">
        <Recommendations />
      </Col>
    </Row>
    <Row>
      <Col className="mb-4">
        <AdSenseBanner
          slot="2696045280"
          responsive
          style={{ display: 'block' }}
        />
      </Col>
    </Row>
    <Row>
      <Col className="mb-3">
        <Makes />
      </Col>
    </Row>
    <SeoDescriptionText />
  </div>
);

export default withStyles(s)(Home);
