exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1z10H,._38CFV{display:-ms-flexbox;display:flex}._38CFV{text-decoration:none!important;font-size:1.3em;color:#333!important;margin:5px 0}._1UfgT{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}", ""]);

// exports
exports.locals = {
	"root": "_1z10H",
	"brand": "_38CFV",
	"toggleMenu": "_1UfgT"
};