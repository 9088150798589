import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './RegionOption.css';

/* eslint-disable jsx-a11y/no-static-element-interactions */

class RegionOption extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
    isFocused: PropTypes.bool,
    onFocus: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    option: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    isFocused: false,
  };

  handleMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  };

  handleMouseEnter = event => {
    this.props.onFocus(this.props.option, event);
  };

  handleMouseMove = event => {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  };

  render() {
    const { className, option, children } = this.props;
    return (
      <div
        className={`${className} ${option.region ? s.region : s.city}`}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        title={option.title}
      >
        {children}
      </div>
    );
  }
}

export default withStyles(s)(RegionOption);
