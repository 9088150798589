import compact from 'lodash/compact';
import split from 'lodash/split';
import last from 'lodash/last';
import concat from 'lodash/concat';
import range from 'lodash/range';
import take from 'lodash/take';

import {
  isRegion,
  getRegion,
  getMake,
  getModel,
} from '../../data/catalog_helpers';

import makeRoute from './make';
import modelRoute from './model';
import regionRoute from './region';

export const resolve = async args => {
  const { path, store, fetch } = args;
  const params = compact(split(path, '/'));
  const canBeRegion = last(params);

  const [
    makeId,
    modelId,
    generationId,
    modificationId,
    regionId,
  ] = (await isRegion(canBeRegion, store, fetch))
    ? concat(
        range(4).map(i => take(params, params.length - 1)[i]),
        [last(params)],
      )
    : params;
  let routeView = null;
  let options = { ...args };

  if (regionId) {
    const region = await getRegion(regionId, store, fetch);
    routeView = regionRoute;
    options = { ...options, region };
  }

  if (makeId) {
    const make = await getMake(makeId, store, fetch);
    if (!make) {
      return null;
    }
    routeView = makeRoute;
    options = { ...options, make };
  }

  if (makeId && modelId) {
    const model = await getModel(makeId, modelId, store, fetch);
    if (!model) {
      return null;
    }
    routeView = modelRoute;
    options = { ...options, model };
  }
  if (makeId && modelId && generationId) {
    // TODO: generation seo page
    return null;
  }

  if (makeId && modelId && generationId && modificationId) {
    // TODO: modification seo page
    return null;
  }

  return { routeView, options };
};

const renderRegion = region => (region ? `${region.slug}/` : '');

export const getRegionUrl = region => `/${renderRegion(region)}`;

export const getMakeUrl = (make, region = null) =>
  `/${make.slug}/${renderRegion(region)}`;

export const getModelUrl = (make, model, region = null) =>
  `${getMakeUrl(make)}${model.slug}/${renderRegion(region)}`;

export const getGenerationUrl = (make, model, generation, region = null) =>
  `${getModelUrl(make, model)}${generation.slug}/${renderRegion(region)}`;

export const getModificationUrl = (
  make,
  model,
  generation,
  modification,
  region = null,
) =>
  `${getGenerationUrl(make, model, generation)}${
    modification.slug
  }/${renderRegion(region)}`;
