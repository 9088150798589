import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'reactstrap';
import map from 'lodash/map';
import chunk from 'lodash/chunk';

import s from './Links.css';
import LinkItem from './LinkItem';

class Links extends Component {
  static propTypes = {
    catalogItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    link: PropTypes.func,
    title: PropTypes.func,
    columns: PropTypes.number,
    icon: PropTypes.func,
  };

  static defaultProps = {
    link: item => `/${item.id}/`,
    title: item => `${item.title}`,
    columns: 2,
    icon: null,
  };

  render() {
    const { catalogItems, columns, link, title, icon } = this.props;

    if (!catalogItems || catalogItems.length === 0) {
      return null;
    }

    const chunkLength = Math.ceil(catalogItems.length / columns);
    return (
      <Row>
        {map(chunk(catalogItems, chunkLength), (chunk_, index) => (
          <Col xs="12" md={12 / columns} key={index}>
            <ul className={`list-unstyled ${s.list}`}>
              {chunk_.map(item => (
                <LinkItem
                  link={link}
                  item={item}
                  key={item.id}
                  title={title}
                  icon={icon}
                />
              ))}
            </ul>
          </Col>
        ))}
      </Row>
    );
  }
}

export default withStyles(s)(Links);
