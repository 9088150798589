import { defineMessages } from 'react-intl';

export default defineMessages({
  'Продаж легкових автомобілів {region}': {
    id: 'seo:region:seo_title',
    defaultMessage: 'Продаж легкових автомобілів {region}',
  },
  'Продаж легкових авто {region}. Б/в та нові. Швидкий підбір авто в регіоні {region}. Актуальні ціни та фото.': {
    id: 'seo:region:seo_descr',
    defaultMessage:
      'Продаж легкових авто {region}. Б/в та нові. Швидкий підбір авто в регіоні {region}. Актуальні ціни та фото.',
  },
  'Продаж легкових автомобілів {make}{region}.': {
    id: 'seo:make:seo_title',
    defaultMessage: 'Продаж легкових автомобілів {make}{region}.',
  },
  'Продаж легкових авто {make} б/в та нові{region}. Швидкий підбір авто. Актуальні ціни та фото. Технічні характеристики автомобілів {make}.': {
    id: 'seo:make:seo_descr',
    defaultMessage:
      'Продаж легкових авто {make} б/в та нові{region}. Швидкий підбір авто. Актуальні ціни та фото. Технічні характеристики автомобілів {make}.',
  },
  'Продаж легкових автомобілів {make} {model}{region}.': {
    id: 'seo:model:seo_title',
    defaultMessage: 'Продаж легкових автомобілів {make} {model}{region}.',
  },
  'Продаж легкових авто {make} {model} б/в та нові{region}. Швидкий підбір авто. Актуальні ціни та фото. Технічні характеристики автомобілів {make} {model}.': {
    id: 'seo:model:seo_descr',
    defaultMessage:
      'Продаж легкових авто {make} {model} б/в та нові{region}. Швидкий підбір авто. Актуальні ціни та фото. Технічні характеристики автомобілів {make} {model}.',
  },
});
