exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._20x3F{margin-bottom:32px;margin-bottom:2rem}.mtUbn{margin-bottom:16px;margin-bottom:1rem}.mtUbn a{font-size:.85em}", ""]);

// exports
exports.locals = {
	"root": "_20x3F",
	"links": "mtUbn"
};