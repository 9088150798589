/* eslint-disable no-shadow */

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';

import { setLocale } from '../../actions/intl';
import s from './LanguageSwitcher.css';
import { makeLocaleUrl, localeDict } from '../../locales';

class LanguageSwitcher extends React.Component {
  static propTypes = {
    currentLocale: PropTypes.string.isRequired,
    availableLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
    setLocale: PropTypes.func.isRequired,
  };

  static contextTypes = {
    fetch: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    query: PropTypes.shape(),
  };

  onClick = locale => {
    const { fetch } = this.context;
    this.props.setLocale(fetch, { locale });
  };

  render() {
    const { currentLocale, availableLocales } = this.props;
    const isSelected = locale => locale === currentLocale;
    const localeName = locale => localeDict[locale] || locale;

    if (currentLocale === 'uk-UA') {
      return null;
    }

    return (
      <div className={s.root}>
        {availableLocales
          .filter(locale => locale !== 'ru-UA')
          .map(locale => (
            <span key={locale}>
              {isSelected(locale) ? (
                <span className={s.selected}>{localeName(locale)}</span>
              ) : (
                <a
                  href={makeLocaleUrl('/', locale)}
                  onClick={e => {
                    this.onClick(locale);
                    e.preventDefault();
                  }}
                >
                  {localeName(locale)}
                </a>
              )}{' '}
            </span>
          ))}
      </div>
    );
  }
}

const mapState = state => ({
  availableLocales: state.runtime.availableLocales,
  currentLocale: state.intl.locale,
});

const mapDispatch = {
  setLocale,
};

export default connect(mapState, mapDispatch)(withStyles(s)(LanguageSwitcher));
