import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col, Card, CardBody } from 'reactstrap';

import AdSenseBanner from 'containers/AdSenseBanner';
import SearchForm from 'containers/Seo/ModelSearchForm';
import { withLocalize } from 'locales';

import Links from '../../Catalog/Links';
import AllMakes from '../AllMakes';
import CarsList from '../CarsList';
import { getModelUrl } from '../../../routes/seo/urls';

import Pagination from '../Pagination';

import s from './ModelPage.css';
import messages from './messages';

class ModelPage extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    cars: PropTypes.shape().isRequired,
    make: PropTypes.shape().isRequired,
    model: PropTypes.shape().isRequired,
    region: PropTypes.shape(),
    makes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    models: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    localize: PropTypes.func.isRequired,
  };

  static defaultProps = {
    region: undefined,
  };

  render() {
    const {
      cars,
      make,
      region,
      locations,
      models,
      makes,
      title,
      model,
      localize,
    } = this.props;

    return (
      <Row className={s.root}>
        <Col md="8">
          <Row>
            <Col md="12">
              <h1>{title}</h1>
              <SearchForm />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <CarsList cars={cars} />
              <Pagination {...this.props} />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <AllMakes
                makes={makes}
                title={localize('Інші марки на Avtoprod')}
              />
            </Col>
          </Row>
        </Col>
        <Col md="4">
          <Row>
            <Col md="12" className="mb-3">
              <AdSenseBanner slot="8181435095" />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className={s.links}>
                <CardBody>
                  <p className="lead">
                    {localize('Інші моделі')} {make.title}
                  </p>
                  <hr className="my-2" />
                  <Links
                    catalogItems={models}
                    link={item => getModelUrl(make, item)}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {region && (
            <Row>
              <Col md="12">
                <Card className={s.links}>
                  <CardBody>
                    <p className="lead">
                      {localize('Інші моделі')} {make.title}{' '}
                      {region ? `+ ${region.title}` : null}
                    </p>
                    <hr className="my-2" />
                    <Links
                      catalogItems={models}
                      link={item => getModelUrl(make, item, region)}
                      title={item =>
                        `${item.title} ${region ? `+ ${region.title}` : ''}`
                      }
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col md="12">
              <Card className={s.links}>
                <CardBody>
                  <p className="lead">
                    {localize('{make} {model} в інших регіонах України', {
                      make: make.title,
                      model: model.title,
                    })}
                  </p>
                  <hr className="my-2" />
                  <Links
                    catalogItems={locations}
                    link={item => getModelUrl(make, model, item)}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withStyles(s)(withLocalize(messages)(ModelPage));
