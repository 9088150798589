import React from 'react';
import PropTypes from 'prop-types';
import thumbnail from 'components/ThumborImage/thumbor';
import { itemUrl } from 'routes/cars/urls';

import JsonLd from './JsonLd';

const Product = ({ product }) => {
  const { id } = product;
  if (!id || !product.images) {
    return null;
  }
  const { make, model, images, price, price_currency: priceCurrency } = product;
  const data = {
    '@context': 'http://schema.org',
    '@type': 'Product',
    name: model.title,
    brand: {
      '@type': 'Brand',
      name: make.title,
    },
    image: thumbnail(images[0].url, 640),
    offers: {
      '@type': 'Offer',
      price,
      priceCurrency,
      url: `https://avtoprod.ua${itemUrl(product)}`,
    },
  };

  return <JsonLd data={data} />;
};

Product.propTypes = {
  product: PropTypes.shape(),
};

Product.defaultProps = {
  product: [],
};

export default Product;
